<app-header></app-header>
<hr>

<div class="searchDivMobile" style="display: flex; align-items: center;">
    <mat-icon (click)="close()">arrow_back</mat-icon>
    <label class="filters" style="flex: 1; text-align: center;">{{ 'searchBar.filters' | translate }}</label>
    <label *ngIf="changedDefaultValues" class="text" (click)="clearFilters()"> {{ 'searchBar.clearFilters' | translate
        }}</label>
</div>


<div class="searchDivMobile pl-5 pr-5 pt-5">            
    <mat-panel-title class=" tittle">
        {{ 'searchBar.labelChooseOption' | translate }}
    </mat-panel-title>

<mat-form-field class="matOption matSelects" appearance="outline">
    <mat-select [(value)]="this.filters.purpose" 
        placeholder="{{ selectedLabel  || ('searchBar.placeHolderLike' | translate) }}">
        <mat-option [value]="" (click)="clearPurpose()">---</mat-option>
        <mat-optgroup value="Rent"> {{ 'searchBar.textRent' | translate }}
            <mat-option  value="'ONE_MONTH'"
                (click)="rentalTime('ONE_MONTH')">{{'rentOptions.one_month'| translate }}</mat-option>
            <mat-option  value="'THREE_MONTHS'"
                (click)="rentalTime('THREE_MONTHS')">{{'rentOptions.three_months'| translate }}</mat-option>
            <mat-option  value="'MORE_THAN_THREE_MONTHS'"
                (click)="rentalTime('MORE_THAN_THREE_MONTHS')">{{'rentOptions.more_than_three_months'|
                translate }}</mat-option>
            <mat-option  value="'ONE_YEAR'"
                (click)="rentalTime('ONE_YEAR')">{{'rentOptions.one_year'| translate }}</mat-option>
        </mat-optgroup>
        <mat-option value="Buy" (click)="changeMinPrice('Buy')"> {{ 'searchBar.textBuy' | translate }} </mat-option>
    </mat-select>
</mat-form-field>

</div>
<hr>

<div class="searchDivMobile">
    <mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ 'searchBar.labelSearchByLocation' | translate }}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div style="display: flex; align-items: center;">
            <mat-form-field id="matSelectCities" class="matOption" appearance="outline">
                <mat-select #ciudad [(value)]="this.filters.city" (valueChange)="setChangedDefaultValues()"
                    placeholder="{{'searchBar.placeHolderSelectLocation' | translate}}">
                    <mat-option [value]="">---</mat-option>
                    <mat-option *ngFor="let city of cities$ | async" [value]="city.id">{{ city.name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-expansion-panel>
</mat-accordion>


</div>



<hr>
<div class="searchDivMobile">
    <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'searchBar.labelPropertyType' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-form-field class="matOption matSelects" appearance="outline">
                <mat-select (valueChange)="changeDefault()" [(value)]="this.filters.type"
                    placeholder="{{'searchBar.placeHolderChooseProperty' | translate}}">
                    <mat-option [value]="">---</mat-option>
                    <mat-option *ngFor="let propertyType of propertyTypes$ | async" [value]="propertyType.id">{{
                        propertyType.name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<hr>
<div class="searchDivMobile">
    <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'searchBar.labelPriceRange' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-ln-price-slider [changedDefaultValues]=changedDefaultValues [valuesReceived]="setValuesReceived()"
                (selectedMinValueEvent)="setMinValue($event)" (selectedMaxValueEvent)="setMaxValue($event)"
                [startThumbValueInput]="minPrice" [startThumbValue]="minPrice" [endThumbValue]="maxPrice"
                [endThumbValueInput]="maxPrice"></app-ln-price-slider>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="grid searchDivMobile">
        <div class="grid grid-cols-2 gap-3 pt-5 pb-5">
            <ln-button [color]="'warn'" [icon]="'search'" [text]="'searchBar.btnSearch' | translate"
                (click)="searchProperties()">
            </ln-button>
            <ln-button [color]="'accent'" [text]="'searchBar.btnSearchInMap' | translate"
                (clickButton)="goToSearchInMap()">
            </ln-button>
        </div>
    </div>
</div>