<section class="side-filter-container">

    <div class="div-label">
        <label>{{label | translate}}</label>
    </div>

    <div class="mt-4 mb-4">
        <ln-filter-chips
                [filterSelected]="getFiltersSelected()"
                (removed)="filtersWasRemoved($event)">
        </ln-filter-chips>
    </div>

    <div class="mb-4 mt-4">
        <ln-filter-additional-services
                *ngFor="let filter of getFiltersServicesList()"
                [filtersServicesItem]="filter"
                (selected)="filterWasSelected($event)"
                (removed)="filtersServicesWasRemoved($event)">
        </ln-filter-additional-services>
    </div>

    <div class="mt-6">
        <ln-filter-list-expansion-panel
                *ngFor="let filter of getFiltersList()"
                [filterItem]="filter"
                (clicked)="filterWasSelected($event)">
        </ln-filter-list-expansion-panel>
    </div>

</section>
