<div *ngIf="apiLoaded$ | async" class="g-map">

    <div class="mt-4 mb-4 sm:ml-0 ml-4" *ngIf="!isPropertyDetails">
        <label *ngIf="posts?.length as postsLength; else notPosts">{{ 'googleMap.textExist' | translate }} {{postsLength}} {{ 'googleMap.textPropertyInArea' | translate }}</label>
        <ng-template #notPosts>
            <label> {{ 'googleMap.textNoProperties' | translate }} </label>
        </ng-template>
    </div>

    <google-map
            width="100%"
            [height]="height"
            [center]="center"
            [zoom]="zoom"
            [options]="options"
            (idle)="refresh()"
            (mapClick)="closeInfoWindows()"
    >

        <div *ngIf="isPropertyDetails; then marketDefault; else markerPer"></div>
        <ng-template #markerPer>
            <map-marker #marker="mapMarker"
                        *ngFor="let post of posts; index as i; trackBy: trackByFn"
                        [icon]="getIcon(post.property.price, post.like)"
                        [options]="markerOptions"
                        [position]="post.property.latLng"
                        (mapClick)="openInfoWindow(marker, post)"
                        (mapMouseover)="setZIndex(marker, 100)"
                        (mapMouseout)="setZIndex(marker, 10)"
            >
                <map-info-window
                        [options]="infoWindowsOptions">
                    <ng-container *ngIf="postSelected">
                        <ln-card-maps
                                [id]="postSelected.id"
                                [image]="postSelected.pictures[0].path.md"
                                [numberOfBedrooms]="postSelected.property.numberOfRooms"
                                [numberOfBathrooms]="postSelected.property.numberOfBathRooms"
                                [totalArea]="postSelected.property.totalArea"
                                [numberOfParkings]="postSelected.property.numberOfParkings"
                                [price]="postSelected.property.price.toString()"
                        ></ln-card-maps>
                    </ng-container>
                </map-info-window>
            </map-marker>
        </ng-template>

        <ng-template #marketDefault>
            <map-marker
                    [options]="markerOptions"
                    [position]="center"
            ></map-marker>
        </ng-template>
    </google-map>

</div>
