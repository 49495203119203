import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CurrencyPipe} from "@angular/common";

@Component({
  selector: 'app-ln-price-slider',
  templateUrl: './ln-price-slider.component.html',
  styleUrls: ['./ln-price-slider.component.css']
})
export class LnPriceSliderComponent implements OnInit{
  minValue: number = 0;
  maxValue: number = 1000000;
  @Input() startThumbValue: number = 10000;
  @Input() startThumbValueInput: number = 10000;
  @Input() endThumbValue: number = 900000;
  @Input() endThumbValueInput: number = 900000;
  @Output() selectedMinValueEvent = new EventEmitter<number>();
  @Output() selectedMaxValueEvent = new EventEmitter<number>();
  @Input() changedDefaultValues: boolean = false;
  @Input() valuesReceived: any = {}

  constructor(private currencyPipe: CurrencyPipe) {
  }

  ngOnInit() {
    if (this.changedDefaultValues) {
      this.startThumbValueInput = this.valuesReceived['minPrice'];
      this.startThumbValue = this.valuesReceived['minPrice'];
      this.endThumbValueInput = this.valuesReceived['maxPrice'];
      this.endThumbValue = this.valuesReceived['maxPrice'];
    }
  }

  emitValues() {
    this.selectedMinValueEvent.emit(this.startThumbValueInput);
    this.selectedMaxValueEvent.emit(this.endThumbValueInput);
  }

  changeInputStart($event: number){
    this.startThumbValueInput = $event;
  }
  changeInputEnd($event: number){
    this.endThumbValueInput = $event;
  }

  changeRangeStart($event: number, input: any){
    this.startThumbValue = Number.parseFloat($event.toString().replace(/[^0-9.]+/g, ''));

    if (Number.isNaN(this.startThumbValue)) {
      this.startThumbValue = 0;
    }

    this.startThumbValueInput = this.startThumbValue;
    input.value = this.currencyPipe.transform(this.startThumbValueInput, 'USD', 'symbol', '1.0');

    this.emitValues();
  }

  changeRangeEnd($event: number, input: any) {
    this.endThumbValue = Number.parseFloat($event.toString().replace(/[^0-9.]+/g, ''));

    if (Number.isNaN(Number(this.endThumbValue))) {
      this.endThumbValue = 0;
    }

    this.endThumbValueInput = this.endThumbValue;
    input.value = this.currencyPipe.transform(this.endThumbValueInput, 'USD', 'symbol', '1.0');

    this.emitValues();
  }
}
