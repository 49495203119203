<div class="flex flex-cols pt-4 pb-4 pl-20 br" >
    <a (click)="goBack()">
        <mat-icon id="arrowBack" class="mr-4">arrow_back</mat-icon>
    </a>
    <p class="color-cafe">{{ 'servicesForm.back' | translate  }}</p>
    <div class="icons">
        <a href="https://wa.me/+50245085136" class="pr-4">
            <img class="icon" src="../../../../assets/icons/whatsapp.svg" alt="" >
        </a>
        <a href="https://www.instagram.com/lunanueva.portal/">
            <img class="icon" src="../../../../assets/icons/instagram.svg" alt="" >
        </a>
    </div>
</div>
<section class="max-height flex items-center justify-center">
    <div class="max-w-sm m-auto">
        <div class="p-6 rounded-lg">
            <h1 class="h1-color">{{ 'servicesForm.labelContactForm' | translate }}</h1>
            <form class="grid grid-cols-1 gap-x-8 gap-y-3 ">
                <ln-form-field-input
                        [control]="formGroup.controls.name"
                        [label]="'servicesForm.labelFullName'"
                        [type]="'text'"
                ></ln-form-field-input>
                <ln-form-field-telephone
                        [control]="formGroup.controls.phone">
                </ln-form-field-telephone>
                <ln-form-field-input
                        [label]="'general.labelEmail'"
                        [type]="'text'"
                        [control]="formGroup.controls.email"
                ></ln-form-field-input>

               
                <ln-form-field-select-multiple
                        
                        [list]="servicesTypeList"
                        [label]="'servicesForm.labelOptionToBeContacted' | translate"
                        [control]="formGroup.controls.services">
                </ln-form-field-select-multiple>
                <div class="flex" style="margin-top: -3%;">
                    <ln-checkbox
                            class="leading-10 grid grid-cols-1"
                            (onChange)="checkBoxChanged($event.checked)"
                    >
                    </ln-checkbox>
                    <div class="ml-5 sm:ml-0 self-center text-primary-color">
                        <span class="cafeColor"> {{ 'servicesForm.labelSendMeACopy' | translate }}
                        </span>
                    </div>
                </div>
            </form>

            <div class="mx-w mt-5">
                <ln-button
                        [text]="'servicesForm.btnSend'"
                        (clickButton)="sendEmails()"
                        [disabled]="formGroup.invalid"
                >
                </ln-button>
            </div>
            <div id="captcha_turnstile" class="flex items-center justify-center sticky bottom-2 mt-5"></div>
        </div>
    </div>
</section>
