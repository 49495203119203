import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {FiltersInterface} from "../../shared/interfaces/filters.interface";
import {PropertyPostListInterface} from "../../shared/interfaces/property-post-list.interface";
import {PropertyPostModel} from "../domain/property-post-model";
import {FavoritePostModel} from "../domain/favorite-post-model";

@Injectable()
export abstract class PropertyPostRepository {
    abstract get(filters?: FiltersInterface): Observable<PropertyPostListInterface>;
    abstract getPostDetails(id: string): Observable<PropertyPostModel>;
    abstract markPostAsFavorite(favoritePost: FavoritePostModel): Observable<any>;
    abstract getFavoriteUserPosts(): Observable<PropertyPostModel[]>;
}
