<footer class="p-4 bg-white shadow md:p-6 footer">
    <div class="flex flex-wrap sm:cols-3 justify-between mx-auto">
        <div class="flex-row">
            <div class="flex">
                <a href="#" class="flex mb-5 mr-4">
                    <img src="../../../../assets/icons/logo.svg" class="mr-3" class="logoPortal" alt="Logo" />
                </a>
                <!-- <a href="#" class="flex mt-1.5 mb-5">
                    <img src="../../../../assets/icons/logoProperties.svg" class="mr-3" alt="Logo" />
                </a> -->
            </div>
           
            <p class="max-w-lg mb-3 text-gray-600 ">
                {{'footer.text1' | translate}} <br>
                {{'footer.text2' | translate}}
            </p>
        </div>
        <div class="servicesDiv">
            <ul class="flex flex-wrap mb-6 text-sm text-gray-500 sm:mb-0">
                <li>
                    <a routerLink="/contact-page-form" class="mr-4 md:mr-6"> {{'footer.linkContact' | translate}}</a>
                </li>
                <li>
                    <a routerLink="/services" class="mr-4 md:mr-6 ">{{'footer.linkServices' | translate}}</a>
                </li>
                <li>
                    <a routerLink="/blog" class="mr-4 md:mr-6">Blog</a>
                </li>
                <li>
                    <a routerLink="/us" class="mr-4 md:mr-6">{{'footer.linkUs' | translate}}</a>
                </li>
                <li>
                    <a routerLink="/services-form">{{'footer.linkPublishProperty' | translate}}</a>
                </li>
            </ul>
            <div class="icons">
                <a href="https://wa.me/+50245085136" class="pr-4">
                    <img class="icon" src="../../../../assets/icons/whatsapp.svg" alt="" >
                </a>
                <a href="https://www.instagram.com/lunanueva.portal/">
                    <img class="icon" src="../../../../assets/icons/instagram.svg" alt="" >
                </a>
            </div>
        </div>
    </div>
    <div class="seiza">
        <a (click)="goToSeizaPage()"><p>{{ 'footer.linkDevelopedBy1' | translate }} <mat-icon>favorite</mat-icon> {{ 'footer.linkDevelopedBy2' | translate }} </p></a>
    </div>
</footer>
