import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ContactFormRepository} from "../../core/repositories/contact-form.repository";
import {ServicesFormModel} from "../../core/domain/services-form-model";
import {ContactFormModel} from "../../core/domain/contact-form-model";

@Injectable({
    providedIn: 'root'
})

export class ContactFormWebRepository extends ContactFormRepository {

    constructor(private _http: HttpClient) {
        super();
    }

    postEmailServices(form: Partial<ServicesFormModel>, captcha: string): Observable<any> {
        const headers = {
            'X-Recaptcha-Token': captcha
        };
        return this._http
            .post<Partial<ServicesFormModel>>(
                `${environment.baseURL}${environment.endpoints.emailServices}`,
                form,
                {headers})
    }

    postEmailContact(form: Partial<ContactFormModel>, captcha: string): Observable<any> {
        const headers = {
            'X-Recaptcha-Token': captcha
        };
        return this._http
            .post<Partial<ServicesFormModel>>(
                `${environment.baseURL}${environment.endpoints.emailContact}`,
                form,
                {headers})
    }

    postEmailSellerContact(postId: string, form: Partial<ContactFormModel>, captcha: string): Observable<any> {
        const headers = {
            'X-Recaptcha-Token': captcha
        };
        return this._http
            .post<Partial<ServicesFormModel>>(
                `${environment.baseURL}${environment.endpoints.emailContact}/${postId}`,
                form,
                {headers})
    }

}
