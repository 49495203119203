import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, Renderer2, ViewChild, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FiltersInterface } from '../../interfaces/filters.interface';
import { Observable, Subject, Subscription, startWith, switchMap, takeUntil, tap } from 'rxjs';
import { PropertyTypeEntity } from 'src/app/data/entity/property-type-entity';
import { CurrencyPipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { CityPageActions, CitySelectors, PropertyTypesPageActions, PropertyTypesSelectors } from './../../../ngrx/index';
import { CityModel } from 'src/app/core/domain/city-model';
import { ActivatedRoute, Router } from '@angular/router';
import { CityUseCaseService } from 'src/app/core/usecase/city-use-case.service';
import { FirebaseService } from '../../services/firebase.service';
import { PropertyTypeUseCaseService } from 'src/app/core/usecase/property-type-use-case.service';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'app-filters-menu-mobile',
  templateUrl: './filters-menu-mobile.component.html',
  styleUrls: ['./filters-menu-mobile.component.css']
})
export class FiltersMenuMobileComponent implements OnInit {
  matDialogRef = inject(MatDialogRef<FiltersMenuMobileComponent>);


  @Input() filtersParent: any
  @Input() filters: FiltersInterface = {};
  @Input() selectedMinValue: number = 0;
  @Input() selectedMaxValue: number = 900000;

  subscriptions: Subscription[] = [];

  cities$!: Observable<CityModel[]>;
  propertyTypes$!: Observable<PropertyTypeEntity[]>;


  changedDefaultValues: boolean = false;
  panelOpenState = false;
  value: number = 0;
  minPrice: number = 0;
  maxPrice: number = 900000
  rangeValues = ""



  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FiltersMenuMobileComponent>,
    private _cityService: CityUseCaseService,
    private _propertyTypeService: PropertyTypeUseCaseService,
    private firebaseService: FirebaseService,
    private _router: Router,
    private _store: Store,
    private currencyPipe: CurrencyPipe,
    private translate: TranslationService
  ) { }

  ngOnInit() {
    
    this.filters = this.data    
    this._getCities();
    this._getPropertyTypes();
    this.setChangedDefaultValues()

    if(this.filters.purpose){
      if (this.filters.purpose == 'Buy'){
      }
      else{this.rentalTime(this.filters.rentalTime!)}
      } 

  }

  setChangedDefaultValues() {
    if (this.filters.city?.length === 3) { this.changedDefaultValues = true } else { this.changedDefaultValues = false }
  }

  changeDefault(){
    this.changedDefaultValues=true
  }
  sendDataBackToParent(): void {
    this.dialogRef.close(this.filters);
  }

  setMinValue(value: number) {
    this.selectedMinValue = value;
    this.changedDefaultValues = true;
  }

  setMaxValue(value: number) {
    this.selectedMaxValue = value;
    this.changedDefaultValues = true;
  }

  goToSearchInMap() {
    this._getFilters();

    this._router.navigate(['search-map'],
      {
        queryParams: this.filters,
        queryParamsHandling: 'merge'
      })
    this.matDialogRef.close()
  }

  searchProperties() {
    this._getFilters();

    this._router.navigate(['search-posts'],
      {
        queryParams: { ...this.filters, 'changedDefaultValues': this.changedDefaultValues },
        queryParamsHandling: 'merge'
      });
    this.close()
  }

  setValuesReceived() {
    let valuesReceived = {};
    if (this.changedDefaultValues) {
      valuesReceived = {
        'minPrice': this.selectedMinValue,
        'maxPrice': this.selectedMaxValue
      }
    }

    return valuesReceived;
  }

  public _getCities() {
    this._store.dispatch(CityPageActions.init());

    this.cities$ = this._store.select(CitySelectors.cities);

  }

  public _getPropertyTypes() {
    this._store.dispatch(PropertyTypesPageActions.init());
    this.propertyTypes$  //this._store.select(PropertyTypesSelectors.propertyTypes);
      = this.translate.onLangChange
        .pipe(
          startWith(() => this.translate.getCurrentLang),
          switchMap(id => this._propertyTypeService.get())
        )
  }

  public _getFilters() {
    if (this.selectedMinValue >= 0 && this.changedDefaultValues) {
      this.filters.minPrice = this.selectedMinValue;
    }

    if (this.selectedMaxValue > 0 && this.changedDefaultValues) {
      this.filters.maxPrice = this.selectedMaxValue;
    }
  }
  close() {
    this._getFilters()
    this.matDialogRef.close(this.filters)
  }
  clearLabel() {
    this.selectedLabel = '';
    this.selectedValue = ''
  }
  clearPurpose() {
    this.selectedValue = ''
    this.filters.purpose = undefined
    this.selectedLabel = '';
    this.rangeValues = ""
    this.filters.rentalTime = undefined
    this.minPrice = 0;
    this.maxPrice = 900000;
    this.filters.maxPrice = this.maxPrice
    this.filters.minPrice = this.minPrice
    this.selectedMaxValue = this.maxPrice
    this.selectedMinValue = this.minPrice

    if (!this.filters.type && !this.filters.city) {
      this.changedDefaultValues = false
    }
  }
  changeMinPrice(value: string) {

    this.selectedValue = value
    if (value === 'Buy' || value === 'Rent') { this.filters.purpose = value }
    this.minPrice = this.filters.purpose === "Rent" ? 30 : 10000;
    this.maxPrice = this.filters.purpose === "Rent" ? 15000 : 900000;
    this.setMaxValue(this.maxPrice)
    this.setMinValue(this.minPrice)
    this.selectedMaxValue = this.maxPrice
    this.selectedMinValue = this.minPrice
    this.rangeValues = this.currencyPipe.transform(this.selectedMinValue, 'USD', 'symbol', '1.0')
      + " - " + this.currencyPipe.transform(this.selectedMaxValue, 'USD', 'symbol', '1.0');
    this.translate.getTranslation(`searchBar.text${value}`).subscribe((translation: string) => {
      this.selectedLabel = translation;
    });
  }

  selectedValue: string = '';
  selectedLabel: string = ''
  clearFilters() {
    this.selectedValue = '';
    this.selectedLabel = ''
    this.filters = {};
    this.minPrice = 0;
    this.maxPrice = 900000;
    this.filters.maxPrice = this.maxPrice
    this.filters.minPrice = this.minPrice
    this.selectedMaxValue = this.maxPrice
    this.selectedMinValue = this.minPrice
    this.changedDefaultValues = false;
    this.rangeValues = ""
  }



  rentalTime(value: string) {
    this.selectedValue = ''
    this.filters.rentalTime = value
    this.selectedValue = value
    this.filters.purpose = "Rent"
    this.changeMinPrice(this.filters.purpose!)

    const standardizedValue = value.toLowerCase();
    const translationKey = `rentOptions.${standardizedValue}`;
    this.translate.getTranslation(translationKey).subscribe((translation: string) => {
      this.selectedLabel = translation;
    });


  }

  setText() {
    if (this.filters.purpose === 'Rent') {
      const standardizedValue = this.filters.rentalTime!.toLowerCase();
      const translationKey = `rentOptions.${standardizedValue}`;
      this.translate.getTranslation(translationKey).subscribe((translation: string) => {
        this.selectedLabel = translation;
      });
    }
    else if (this.filters.purpose) {
      this.translate.getTranslation(`searchBar.text${this.filters.purpose}`).subscribe((translation: string) => {
        this.selectedLabel = translation;
      });
    }
  }


  ngOnDestroy(): void {
    this.clearFilters()
    this.subscriptions.forEach(subs => subs.unsubscribe())
  }


}
