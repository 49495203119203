import {Component} from '@angular/core';

@Component({
    selector: 'app-auth-layout',
    templateUrl: './auth-layout.component.html',
    styles: [`
        .button-center {
            position: fixed;
            bottom: 0.5rem;
            left: 50%;
            transform: translateX(-50%);
        }
    `]
})
export class AuthLayoutComponent {
}
