<form *ngIf="filterSelected?.length">
    <mat-chip-listbox>
        <mat-chip color="primary" *ngFor="let filter of filterSelected"
                  (removed)="remove(filter)">
            {{filter.value | translate}}
            <button *ngIf="!filter.isImmutable" matChipRemove>
                <mat-icon>close</mat-icon>
            </button>
        </mat-chip>
    </mat-chip-listbox>
</form>
