import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable, take } from "rxjs";
import { PublisherModel } from "src/app/core/domain/publisher-model";
import { PropertyPostUseCaseService } from "src/app/core/usecase/property-post-use-case.service";
import { PublisherUseCaseService } from "src/app/core/usecase/publisher-use-case.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { DialogService } from "src/app/shared/services/dialog.service";
import { FirebaseService } from "src/app/shared/services/firebase.service";
import { ModalService } from "src/app/shared/services/modal.service";

@Component({
  selector: "ln-publisher-card",
  templateUrl: "./ln-publisher-card.component.html",
  styleUrls: ["./ln-publisher-card.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LnPublisherCardComponent implements OnInit{
  @Input() publishers!: PublisherModel | null;
  @Input() showDeleteOption = false;
  @Output() favoritePostDeleted = new EventEmitter<Boolean>();
  @Input() showDeactivatedPost = false;
 

  isLogin = this._firebaseService.isAuthenticatedUser();

  constructor(
    private _auth: AuthService,
    private _dialogService: DialogService,
    private _firebaseService: FirebaseService,
    private _publisherService: PublisherUseCaseService,
    private _router: Router,
    private _modal: ModalService,
    private translate: TranslateService
  ) {}
  ngOnInit(  ) {
   console.log(this.publishers)
  }

  openIframeDialog(path: string) {
    this._dialogService.openIframeDialog(path).pipe(take(1));
  }

  goToRealState(id: string) {
    this._router.navigateByUrl(`real-state/${id}`);
  }
}
