import {Component, Input} from '@angular/core';

import {TranslationService} from '../../../services/translation.service';


@Component({
    selector: 'ln-translate',
    templateUrl: './ln-translate.component.html',
    styleUrls: ['./ln-translate.component.css']
})
export class LnTranslateComponent {

    @Input() showText = true
    private lang!: string
    langLabel!: string
    iconLanguage!: string

    constructor(private translationService: TranslationService) {
        this.lang = this.translationService.getCurrentLang
        this.selectFlagLang()
    }

    selectFlagLang() {
        this.langLabel = this.lang === 'en' ? 'English' : 'Español';
        this.iconLanguage = this.lang === 'en'
            ? '../../../../assets/icons/english-flag.svg'
            : '../../../../assets/icons/spanish-flag.svg'
    }

    changeLang(): void {
        this.lang = this.lang === 'es' ? 'en' : 'es';
        this.translationService.useLang(this.lang);
        this.selectFlagLang()
    }
}
