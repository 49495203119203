import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CaptchaService {

  async getCaptchaToken(): Promise<string> {
    return await new Promise((resolve, reject) => {
      window.turnstile.render('#captcha_turnstile', {
        sitekey: environment.recaptcha.publicKey,
        theme: 'light',
        callback: function (token: string) {
          resolve(token)
        }
      })
    })
  }
}
