import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {FirebaseService} from "../services/firebase.service";
import {from, Observable} from "rxjs";

@Directive({
    selector: '[appAuthStateDirective]'
})
export class AuthStateDirectiveDirective {

    @Input() appAuthStateDirective!: Observable<any>;

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private firebase: FirebaseService
    ) {
    }

    ngOnInit() {
        this.appAuthStateDirective.subscribe((authUser) => {
            if (!authUser) {
                this.viewContainer.createEmbeddedView(this.templateRef)
            } else {
                this.viewContainer.clear()
            }
        });
    }
}
