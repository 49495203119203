import {Component} from '@angular/core';
import {Observable} from "rxjs";

import {ModalService} from "../../../services/modal.service";
import {ModalInterface} from "../../../interfaces/modal.interface";


@Component({
  selector: 'ln-alert',
  templateUrl: './ln-alert.component.html',
  styleUrls: ['./ln-alert.component.css']
})
export class LnAlertComponent {

  modal$: Observable<ModalInterface>

  constructor(private modal: ModalService) {
    this.modal$ = modal.modal$
  }

  getAlertDisplay(type: string | undefined) {
    switch (type) {
      case "WARNING":
        return 'warning'
      case "ERROR":
        return 'cancel'
      case "SUCCESS":
        return 'done'
      default:
        return 'cancel'
    }
  }

}
