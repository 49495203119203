import {inject, Injectable} from '@angular/core';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {take} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class BreakPointService {

    isMobile = false;

    private _size = inject(BreakpointObserver);

    constructor() {
        this._getViewSize();
    }

    private _getViewSize() {
        this._size.observe([
            Breakpoints.Medium,
            Breakpoints.Small,
            Breakpoints.XSmall,
        ]).pipe(take(1))
            .subscribe((result: { matches: any }) => {
                if (Breakpoints.XSmall && result.matches) {
                    this.isMobile = true
                } else if (Breakpoints.Large || Breakpoints.Medium && result.matches) {
                    this.isMobile = false
                }
            })
    }
}
