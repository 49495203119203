<div class="color-ln flex justify-center items-center flex-col h-full">
    <div class="color-white c-404">404</div>
    <div class="color-white c-title">
        {{ 'notFound.textPageNotFound' | translate }}
    </div>
    <div class="color-white c-subtitle">
        {{ 'notFound.textDeletedPage' | translate }}
    </div>
    <button mat-raised-button class="button" [routerLink]="''">Ir al inicio</button>
</div>
