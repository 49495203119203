import {inject, Injectable} from '@angular/core';
import {DomSanitizer, SafeResourceUrl, SafeUrl} from "@angular/platform-browser";

@Injectable({
    providedIn: 'root'
})
export class SanitizerService {

    public sanitizer = inject(DomSanitizer);

    getSecurityTrustResourceUrl(url: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    getSecurityTrustUrl(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    getSecurityTrustScript(script: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustScript(script);
    }

}
