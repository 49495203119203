import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ThemePalette} from "@angular/material/core";

@Component({
    selector: 'ln-button',
    templateUrl: './ln-button.component.html',
    styleUrls: ['./ln-button.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class LnButtonComponent {

    @Input() text: string = 'Button'
    @Input() icon!: string
    @Input() disabled: boolean = false
    @Input() color: ThemePalette = 'primary';

    @Output() clickButton = new EventEmitter<boolean>();

    onClick() {
        this.clickButton.emit(true);
    }

}
