<div *ngIf="modal$ | async as modals">
    <div
            *ngIf="modals.show"
            [ngClass]="getAlertDisplay(modals.type)"
            [class.blur-out-contract]="modals.effect"
            class="alert-content flex p-4 m-4 text-sm w-4/5 sm:ml-24">
        <div class="flex-d">
            <mat-icon class="sm:mr-8 mr-3 sm:mt-2 mt-4">{{getAlertDisplay(modals.type)}}</mat-icon>
            <div class="font-medium">
                <ul>
                    <li>{{modals.title }}</li>
                    <li>{{modals.description }}</li>
                </ul>
            </div>
        </div>
    </div>
</div>
