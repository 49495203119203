import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {PostsPageActions, PostsApiActions, PostsSelectors} from '../index';
import {EMPTY, exhaustMap, map, withLatestFrom} from "rxjs";
import {Store} from "@ngrx/store";
import {PropertyPostUseCaseService} from "../../core/usecase/property-post-use-case.service";


@Injectable()
export class PostsEffects {

    constructor(
        private actions$: Actions,
        private _propertyPostUseCaseService: PropertyPostUseCaseService,
        private _store: Store
    ) {
    }

    loadLastPost$ = createEffect(() => this.actions$.pipe(
        ofType(PostsPageActions.lastPosts),
        withLatestFrom(this._store.select(PostsSelectors.lastPosts)),
        exhaustMap((initAction) => {
                return this._propertyPostUseCaseService
                    .get(initAction[0].filters)
                    .pipe(
                        map(val => val.data),
                        map((lastPosts) => PostsApiActions.loadLastPosts({lastPosts}))
                    )
        })
    ))
}
