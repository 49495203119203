export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: "AIzaSyBeJBAckugwdEH8HzWrP7qv957f0bAscVg",
        authDomain: "sza-preprod.firebaseapp.com",
        projectId: "sza-preprod",
        storageBucket: "https://dev.staticstorage.lunanuevaportal.com",
        messagingSenderId: "359616739836",
        appId: "1:359616739836:web:d7d99df49a2bc920221a79",
        measurementId: "G-HR5982B05J"
    },
    tenantId: "dev-lunanueva-user-1lhu3",
    baseURL: "https://dev.api.lunanuevaportal.com/api/v1/",
    endpoints: {
        session: 'session',
        register: 'register',
        cities: "cities",
        propertyTypes: "property-types",
        properties: "properties",
        posts: "posts",
        emailServices: "email/services",
        emailContact: "email/contact",
        postAsFavorite: "mark-favorite",
        realState: "real-state",
        userData: "user-data",
        userPosts: "posts-user",
        publisher:"publisher"
    },
    firebaseVerify: "https://www.googleapis.com/identitytoolkit/v3/relyingparty/setAccountInfo?key=AIzaSyDGEN8PeSAfqRlwA-Z9wUwnalSm7To0_l4",
    googleMapApiKey: "AIzaSyCJx9U6UPxx8rNXXhVCdS3WK9RBvFf4HGc",
    recaptcha: {
        publicKey: "0x4AAAAAAACrvYbrkxvyQt_k"
    },
    storage: {
        pdf: 'https://dev.staticstorage.lunanuevaportal.com/Terms and Conditions/Condiciones y políticas de seguridad.pdf'
    },
    countryUtilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js"
};
