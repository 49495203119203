<div class="text-xs">
    <mat-label>{{ label | translate }}</mat-label>
    <mat-form-field appearance="outline">
        <input matInput [type]="type" [formControl]="control"/>
        <mat-icon matSuffix class="cursor-pointer" (click)="showPassword()" matTooltip="Mostrar/Ocultar Contraseña">
            {{ icon }}
        </mat-icon>
        <mat-hint>{{ hintText | translate }}</mat-hint>
        <mat-error *ngIf="control.hasError('required') && control.touched" class="form-error-msg">
            {{ 'fieldInput.msgRequiredField' | translate }} *
        </mat-error>
        <mat-error *ngIf="control.hasError('password-invalid') && control.touched  && !control.pristine"
                   class="form-error-msg">
            {{ 'fieldInput.msgPasswordError' | translate }}
        </mat-error>
        <mat-error *ngIf="control.hasError('not-same') && control.touched  && !control.pristine"
                   class="form-error-msg">
            {{ 'fieldInput.msgPasswordMatch' | translate }}
        </mat-error>
    </mat-form-field>

</div>
