import { Component } from '@angular/core';

@Component({
  selector: 'app-ln-back-button',
  templateUrl: './ln-back-button.component.html',
  styleUrls: ['./ln-back-button.component.css']
})
export class LnBackButtonComponent {

}
