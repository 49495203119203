<nav class="bg-white border-gray-200 rounded header">
    <div class="flex md:hidden pb-8 text-[#372121] leading-[16.41px]">
        <app-city-menu (citySelected)="onCitySelected($event)" [space]="'space-x-2'" [text]="'text-[14px]'"></app-city-menu>
      </div>
      
    <div class="flex flex-wrap items-end justify-between mx-auto">
        <div class="flex py-[16px]">
            <a href="/" class="flex items-center cursor-pointer">
                <img src="../../../../assets/icons/logo.svg" class="h-16" alt="Logo" />
            </a>
        </div>
        <ng-container *ngIf="show">
            <div class="flex-col flex flex-wrap items-end">

                <div class="hidden md:flex text-[#372121] leading-[18.75px]">
                    <app-city-menu (citySelected)="onCitySelected($event)" [space]="'space-x-3'" [text]="'text-[16px]'"></app-city-menu>
                  </div>
                  
                  
                <div class="flex flex-wrap items-center">
                    <div class="flex items-center md:order-2 ml-3.5">
                        <ln-button [text]="'header.linkContact' | translate" (clickButton)="redirectContact()">
                        </ln-button>
                        <ln-translate [showText]="false" class="mr-3 md:hidden" />
                        <button type="button" class="inline-flex items-center p-2 text-sm rounded-lg md:hidden w-auto"
                            (click)="showMenuMobile()"><span class="sr-only">Abrir menu principal</span>
                            <mat-icon aria-hidden="false" aria-label="Example home icon">{{ icon }}</mat-icon>
                        </button>
                    </div>
                    <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1">
                        <ul
                            class="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
                            <li>
                                <ln-translate />
                            </li>
                            <!-- <li><a [routerLink]="['contact-page-form']"
                                    class="block py-2 pl-3 pr-4 a-secondary-color rounded md:bg-transparent md:p-0"
                                    aria-current="page">{{'header.linkContact' | translate}}</a></li> -->
    
                            <li><a [routerLink]="['blog']"
                                class="block py-2 pl-3 pr-4 a-secondary-color rounded md:bg-transparent md:p-0"
                                aria-current="page">{{'header.linkBlog' | translate}}</a></li>

                            <li><a [routerLink]="['services']"
                                class="block py-2 pl-3 pr-4 a-secondary-color rounded md:bg-transparent md:p-0"
                                aria-current="page">{{'header.linkServices' | translate}}</a></li>
    
                            <li><a [routerLink]="['us']"
                                    class="block py-2 pl-3 pr-4 a-secondary-color rounded md:bg-transparent md:p-0"
                                    aria-current="page">{{'header.linkUs' | translate}}</a></li>
                            <li *ngIf="isLogedin">
                                <a [routerLink]="['my-account/my-data']"
                                    class="block py-2 pl-3 pr-4 a-secondary-color rounded md:bg-transparent md:p-0"
                                    aria-current="page">{{'header.linkMyAccount' | translate}}</a>
                            </li>
                            <li *ngIf="!isLogedin">
                                <a [routerLink]="['sessions/register']"
                                    class="a-secondary-color block py-2 pl-3 pr-4 rounded md:bg-transparent md:p-0">
                                    {{'header.linkCreateAccount' | translate}}</a>
                            </li>
                            <li *ngIf="!isLogedin">
                                <a [routerLink]="['sessions/signin']"
                                    class="a-secondary-color block py-2 pl-3 pr-4 roundedmd:bg-transparent md:p-0">
                                    {{'header.linkLogin' | translate}}</a>
                            </li>
                            <li *ngIf="isLogedin">
                                <a (click)="openDialogCloseSession()"
                                    class="a-secondary-color block py-2 pl-3 cursor-pointer pr-4 rounded md:bg-transparent md:p-0">
                                    {{'header.linkLogout' | translate}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- mobile -->
            <div class="w-full md:hidden">
                <div class="items-center justify-between w-full  md:flex md:w-auto menuMobile"
                    [class.showMenu]="showMenu" id="navbar-cta">
                    <ul class="flex flex-col mt-4 md:flex-row md:mt-0 md:text-sm md:font-medium divide-y">

                        <li *ngIf="!isLogedin">
                            <a [routerLink]="['sessions/signin']"
                                class="a-secondary-color block py-2 pl-3 pr-4 rounded md:bg-transparent md:p-0">
                                {{'header.linkLogin' | translate}}</a>
                        </li>
                        <li *ngIf="isLogedin">
                            <a (click)="openDialogCloseSession()"
                                class="a-secondary-color block py-2 pl-3 cursor-pointer pr-4 rounded md:bg-transparent md:p-0">
                                {{'header.linkLogout' | translate}}</a>
                        </li>

                        <li *ngIf="!isLogedin">
                            <a [routerLink]="['sessions/register']"
                                class="a-secondary-color block py-2 pl-3 pr-4 rounded md:bg-transparent md:p-0">
                                {{'header.linkCreateAccount' | translate}}</a>
                        </li>

                        <!-- <li><a [routerLink]="['contact-page-form']"
                                class="block py-2 pl-3 pr-4 a-secondary-color rounded md:bg-transparent md:p-0"
                                aria-current="page">{{'header.linkContact' | translate}}</a></li> -->

                        <li><a [routerLink]="['us']"
                            class="block py-2 pl-3 pr-4 a-secondary-color rounded md:bg-transparent md:p-0"
                            aria-current="page">{{'header.linkUs' | translate}}</a></li>

                        <li><a [routerLink]="['services']"
                                class="block py-2 pl-3 pr-4 a-secondary-color rounded md:bg-transparent md:p-0"
                                aria-current="page">{{'header.linkServices' | translate}}</a></li>

                        <li><a [routerLink]="['blog']"
                            class="block py-2 pl-3 pr-4 a-secondary-color rounded md:bg-transparent md:p-0"
                            aria-current="page">{{'header.linkBlog' | translate}}</a></li>
  
                        <li *ngIf="isLogedin">
                            <a [routerLink]="['my-account/my-data']"
                                class="block py-2 pl-3 pr-4 a-secondary-color rounded md:bg-transparent md:p-0"
                                aria-current="page">{{ 'header.linkMyAccount' | translate }}</a>
                        </li>
                    </ul>
                    <hr>
                    <div class="mt-3">
                        <ln-button [text]="'header.linkContact' | translate" (clickButton)="redirectContact()"
                            class="py-2 pl-3 pr-4 rounded md:bg-transparent md:p-0 showPublishBtn"></ln-button>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</nav>