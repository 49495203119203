import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'ln-dialog-info',
    templateUrl: './ln-dialog-info.component.html',
    styleUrls: ['./ln-dialog-info.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LnDialogInfoComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<LnDialogInfoComponent>
    ) {
    }

    cancel() {
        this.dialogRef.close(false);
    }

}
