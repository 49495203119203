import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
    selector: 'ln-form-field-select',
    templateUrl: './ln-form-field-select.component.html',
    styleUrls: ['./ln-form-field-select.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LnFormFieldSelectComponent implements OnInit {
    @Input() control: FormControl = new FormControl<any>({});
    @Input() error = 'Campo requerido *';
    @Input() hintText = '';
    @Input() label = 'Label';
    @Input() list: { key: string, value: any }[] = [];

    @Output() onSelect: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit(): void {
    }

    select({value}: any) {
        this.onSelect.emit(value);
    }

}
