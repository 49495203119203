import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FiltersChips} from "../../meta/filter-side-menu-meta";

@Component({
    selector: 'ln-filter-chips',
    templateUrl: './ln-filter-chips.component.html',
    styleUrls: ['./ln-filter-chips.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LnFilterChipsComponent {
    @Input() filterSelected: FiltersChips[] = [];

    @Output() removed = new EventEmitter<FiltersChips>();

    remove(filter: FiltersChips): void {
        this.removed.emit(filter);
    }

}
