import {Component, OnDestroy} from '@angular/core';
import {ContactFormTexts} from "../../shared/components/services-form/contact-form-texts";
import {Subscription, take} from "rxjs";
import {ContactFormUseCaseService} from "../../core/usecase/contact-form-use-case.service";
import {ModalService} from "../../shared/services/modal.service";
import {DialogService} from "../../shared/services/dialog.service";
import {Router} from "@angular/router";
import {ServicesFormModel} from "../../core/domain/services-form-model";
import {ContactFormModel} from "../../core/domain/contact-form-model";

@Component({
  selector: 'app-services-page-form',
  templateUrl: './services-page-form.component.html',
  styleUrls: ['./services-page-form.component.css']
})
export class ServicesPageFormComponent implements OnDestroy {

    subscriptions: Subscription[] = [];
    
    constructor(
        private _contactFormUseCaseService: ContactFormUseCaseService,
        private _modal: ModalService,
        private _dialogService: DialogService,
        private _router: Router) {
    }

    sendEmails($item: any) {
        
        const { captcha, ...rest } = $item;

        let data: any = {
            "es": [],
            "en": []
        };

        rest['services'].forEach((item: any) => {
            data["es"].push(item.split(",")[0]);
            data["en"].push(item.split(",")[1]);
        });

        rest['services'] = data;
        
       

        this.subscriptions.push(this._contactFormUseCaseService.postEmailServices(<ContactFormModel>{...rest}, captcha)
            .subscribe({
                next: (data) => {
                    this._dialogService.openInfoDialog(ContactFormTexts.infoDialog.buttonText, '', ContactFormTexts.infoDialog.textBody, ContactFormTexts.infoDialog.textHeader)
                        .pipe(take(1))
                        .subscribe(() => this.goToHomePage())
                },
                error: () => {
                    this._modal.show('contactPageForm.textErrorOccur', 'contactPageForm.textErrorExplanation', "ERROR", 5)
                }
            })
        );
    }

    ngOnDestroy() {
        if (this.subscriptions)
            this.subscriptions.forEach(subs => subs.unsubscribe());
    }

    goToHomePage(): void {
        this._router.navigate(['/']);
    }
}
