
<mat-card class="custom-card" >
    <div *ngIf="publishers && publishers.logo && publishers.logo.length > 0">
        <img mat-card-image class="img-100 div-img" [ngSrc]="publishers.logo" width="100" height="100" priority
             >
    </div> 
    <div  >
        <img src="../../../../../assets/images/noImage.jpg" height="9rem" width="100%" priority>
    </div>
    <mat-card-content>
        <div>
            <div id="publisherAndPriceRow" class="grid grid-cols-2 mt-4 flex-d">
                <div class="div-price">
                    <span>
                       name 
                    </span>
                </div>
                
            </div>
    </div>
    </mat-card-content>
</mat-card>
