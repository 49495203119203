<div class="text-xs">
    <mat-label>{{ label | translate }}</mat-label>
    <mat-form-field appearance="outline">
        <mat-select [formControl]="control" multiple (selectionChange)="select($event)">
            <mat-option *ngFor="let item of list" [value]="item.value | translate ">{{ item.key | translate}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-error style="padding-left: 16px;" *ngIf="control.hasError('required') && control.touched" class="form-error-msg">
        {{ 'fieldInput.msgRequiredField' | translate }}
    </mat-error>
</div>