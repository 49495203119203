import {Component, Input, OnInit, ViewChild, inject,ChangeDetectorRef} from '@angular/core';
import {PropertyTypeEntity} from "../../../data/entity/property-type-entity";
import {Observable, Subscription, switchMap, startWith, take} from "rxjs";
import {CityUseCaseService} from "../../../core/usecase/city-use-case.service";
import {PropertyTypeUseCaseService} from "../../../core/usecase/property-type-use-case.service";
import {FirebaseService} from "../../services/firebase.service";
import {CityModel} from "../../../core/domain/city-model";
import {Router} from "@angular/router";
import {FiltersInterface} from "../../interfaces/filters.interface";
import {Store} from "@ngrx/store";
import {CityPageActions, CitySelectors, PropertyTypesPageActions, PropertyTypesSelectors} from './../../../ngrx/index';
import {MatDialog} from '@angular/material/dialog';
import {FiltersMenuMobileComponent} from '../filters-menu-mobile/filters-menu-mobile.component';
import {CurrencyPipe} from '@angular/common';
import {TranslationService} from "../../services/translation.service";


@Component({
    selector: 'app-search-bar-mobile',
    templateUrl: './search-bar-mobile.component.html',
    styleUrls: ['./search-bar-mobile.component.css']
})
export class SearchBarMobileComponent implements OnInit {

    private _matDialog = inject(MatDialog);
    @Input() back: string = '/';
    @Input() label: string = '';

    @ViewChild('filtersMenuMobile') filtersMenuMobile!: FiltersMenuMobileComponent;
    @Input() selectedMinValue: number = 10_000;
    @Input() selectedMaxValue: number = 900_000;
    @Input() filters: FiltersInterface = {};
    subscriptions: Subscription[] = [];

    cities$!: Observable<CityModel[]>;
    propertyTypes$!: Observable<PropertyTypeEntity[]>;

    changedDefaultValues: boolean = false;
    panelOpenState = false;
    value: number = 0;
    minPrice: number = 100;
    maxPrice:number =900000
    rangeValues=""

    constructor(
        private _cityService: CityUseCaseService,
        private _propertyTypeService: PropertyTypeUseCaseService,
        private firebaseService: FirebaseService,
        private _router: Router,
        private _store: Store,
        private currencyPipe: CurrencyPipe,
        private cdRef: ChangeDetectorRef,
        private translate: TranslationService
    ) {
    }

    ngOnInit() {
        this._getCities();
        this._getPropertyTypes();
        this.minPrice = this.filters.purpose == 'Rent' ? 100 : 10000;
    }
    
    ngafterViewInit() {
        this.setMaxValue(this.maxPrice);
        this.setMinValue(this.minPrice);
      }

    setMinValue(value: number) {
        this.selectedMinValue = value;
        this.changedDefaultValues = true;
    }

    setMaxValue(value: number) {
        this.selectedMaxValue = value;
        this.changedDefaultValues = true;
    }

    goToSearchInMap() {
        this._getFilters();

        this._router.navigate(['search-map'],
            {
                queryParams: this.filters,
                queryParamsHandling: 'merge'
            })
    }

    searchProperties() {
        if(this.filters.purpose==='Buy'){this.filters.rentalTime=undefined}
        if(this.filters.purpose==='Rent'){this.filters.rentalTime=undefined}
        this._getFilters();

        this._router.navigate(['search-posts'],
            {
                queryParams: {...this.filters, 'changedDefaultValues': this.changedDefaultValues},
                queryParamsHandling: 'merge'
            });
    }

    setValuesReceived() {
        let valuesReceived = {};
        if (this.changedDefaultValues) {
            valuesReceived = {
                'minPrice': this.selectedMinValue,
                'maxPrice': this.selectedMaxValue
            }
        }

        return valuesReceived;
    }

    private _getCities() {
        this._store.dispatch(CityPageActions.init());
        this.cities$ = this._store.select(CitySelectors.cities);

    }

    private _getPropertyTypes() {
        this._store.dispatch(PropertyTypesPageActions.init());
        this.propertyTypes$
            = this.translate.onLangChange
            .pipe(
                startWith(() => this.translate.getCurrentLang),
                switchMap(id => this._propertyTypeService.get())
            )
    }

    private _getFilters() {
        if (this.selectedMinValue >= 0 && this.changedDefaultValues) {
            this.filters.minPrice = this.selectedMinValue;
        }

        if (this.selectedMaxValue > 0 && this.changedDefaultValues) {
            this.filters.maxPrice = this.selectedMaxValue;
        }
    }

    changeMinPrice(value: string) {

        this.selectedValue = value
        if(value==='Buy' || value==='Rent' ){this.filters.purpose=value}
        this.minPrice = this.filters.purpose === "Rent" ? 30 : 10000;
        this.maxPrice = this.filters.purpose === "Rent" ? 15000 : 900000;
        this.setMaxValue(this.maxPrice)
        this.setMinValue(this.minPrice)
        this.selectedMaxValue = this.maxPrice
        this.selectedMinValue = this.minPrice
        this.rangeValues = this.currencyPipe.transform(this.selectedMinValue, 'USD', 'symbol', '1.0')
            + " - " + this.currencyPipe.transform(this.selectedMaxValue, 'USD', 'symbol', '1.0');
        this.translate.getTranslation(`searchBar.text${value}`).subscribe((translation: string) => {
            this.selectedLabel = translation;
        });
    }

    clearFilters() {
        this.rangeValues = ""
        this.selectedLabel = '';
        this.filters = {};
        this.selectedValue = ''
        this.minPrice = 0;
        this.maxPrice = 900000;
        this.filters.maxPrice = this.maxPrice
        this.filters.minPrice = this.minPrice
        this.selectedMaxValue = this.maxPrice
        this.selectedMinValue = this.minPrice
        this.changedDefaultValues = false;
        
    }

      setChangedDefaultValues() {
        if(this.filters){this.changedDefaultValues = true;}
      }


      showFilters() {
        this._matDialog.open(FiltersMenuMobileComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
            disableClose: true,
            data:this.filters
        }).afterClosed()
            .pipe(take(1))
            .subscribe(filters => {
                  this.filters = filters;

                  this.cdRef.detectChanges();

              });
    }


    selectedValue: string = '';
    selectedLabel: string = '';
    rentalTime(value: string) {
        this.selectedValue = ''
        this.filters.rentalTime = value
        this.selectedValue = value
        this.filters.purpose = "Rent"
        this.changeMinPrice(this.filters.purpose)
        
        const standardizedValue = value.toLowerCase();
        const translationKey = `rentOptions.${standardizedValue}`;
        this.translate.getTranslation(translationKey).subscribe((translation: string) => {
            this.selectedLabel = translation;
        });


    }
    clearPurpose() {
        this.selectedValue = ''
        this.filters.purpose = undefined
        this.selectedLabel = '';
        this.rangeValues = ""
        this.filters.rentalTime = undefined
        this.minPrice = 0;
        this.maxPrice = 900000;
        this.filters.maxPrice = this.maxPrice
        this.filters.minPrice = this.minPrice
        this.selectedMaxValue = this.maxPrice
        this.selectedMinValue = this.minPrice
    
        if (!this.filters.type && !this.filters.city) {
          this.changedDefaultValues = false
        }
      }

}
