import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {passwordValidator} from "../../../validators/password.validator";

@Component({
    selector: 'ln-form-field-password',
    templateUrl: './ln-form-field-password.component.html',
    styleUrls: ['./ln-form-field-password.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LnFormFieldPasswordComponent implements OnInit {

    @Input() control: FormControl = new FormControl<any>({});
    @Input() hintText = '';
    @Input() label = 'Password';
    @Input() validatePassword = false;
    @Input() validatePasswordRepeat = false;

    icon: 'visibility' | 'visibility_off' = 'visibility_off';
    type: 'text' | 'password' = 'password';
    show = false;

    ngOnInit() {
        if (this.validatePassword) {
            this.control.addValidators(passwordValidator)
            this.control.updateValueAndValidity()
        }
    }

    showPassword() {
        this.show = !this.show
        this.icon = this.show ? 'visibility' : 'visibility_off'
        this.type = this.show ? 'text' : 'password'
    }
}
