<div class="header">
    <button mat-icon-button class="close-button" (click)="dialogRef.close(true)">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
    <h2 class="text-center" matDialogTitle>{{data.title.split(' ')[0] | translate}} {{ data.title.split(' ')[1]}}</h2>
</div>
<mat-dialog-content class="mat-typography">
    <div class="text-center text-xl mt-4 ln-green">{{data.textHeader | translate}}</div>
    <p class="text-center text-lg ln-green">{{data.textBody | translate}}</p>
</mat-dialog-content>
<mat-dialog-actions align="center" class="mbs-16">
    <div *ngIf="data.buttonTextCancel" class="mx-w sm:mr-8 sm:mb-0 mb-8 mr-0 ">
        <button (click)="cancel()" mat-stroked-button color="primary" >{{data.buttonTextCancel | translate}}</button>
    </div>
    <div class="mx-w">
        <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>
            {{ (data.buttonText | translate) ?? 'servicesFormDialog.buttonAccept' | translate }}
        </button>
    </div>
</mat-dialog-actions>
