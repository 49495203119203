import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

import {PublisherModel} from "../domain/publisher-model";
import { PublisherListInterface } from "src/app/shared/interfaces/publisher.interface";


@Injectable()
export abstract class PublisherRepository {
    abstract getPublisher(): Observable<PublisherModel>;
    abstract get(): Observable<PublisherListInterface>;
    
}