<div class="div-paginator">
    <mat-paginator #paginator
                   class="mt-6"
                   aria-label="Seleccionar"
                   (page)="handlePageEvent($event)"
                   [length]="length"
                   [pageSize]="pageSize"
                   [disabled]="disabled"
                   [showFirstLastButtons]="showFirstLastButtons"
                   [hidePageSize]="true"
                   [pageIndex]="pageIndex">
    </mat-paginator>
</div>
