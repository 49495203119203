import {createReducer, on} from '@ngrx/store';
import {PostsApiActions} from './../index';
import {PropertyPostModel} from "../../core/domain/property-post-model";

export const lastPostsStateKey = 'lastPostsState';

export interface LastsPostsState {
    lastPosts: PropertyPostModel[]
}

const initialState: LastsPostsState = {
    lastPosts: []
}

export const lastPostsReducer = createReducer(
    initialState,
    on(PostsApiActions.loadLastPosts, (currentState, action) => ({
        ...currentState,
        lastPosts: action.lastPosts
    }))
)
