import { Component, OnInit } from '@angular/core';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private translationService: TranslationService) {
  }

  ngOnInit(): void {
  }

  goToSeizaPage() {
    window.open("http://seiza-ti.cl", '_blank');
  }
}