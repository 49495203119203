import { Component, Input } from '@angular/core';

@Component({
  selector: 'ln-card-service',
  templateUrl: './ln-card-service.component.html',
  styleUrls: ['./ln-card-service.component.css']
})
export class LnCardServiceComponent {
  @Input() image:string="";
  @Input() title:string="";
  @Input() textOne:string="";
  @Input() textTwo:string="";
  @Input() linkDetail:string="/service-detail";
  @Input() linkText:string="Más información"
  @Input() dateInformation:string="";
  @Input() id:string='';
  @Input() height:number=512;
  @Input() content:boolean=false;
}
