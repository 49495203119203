import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-ln-dialog-iframe',
    templateUrl: './ln-dialog-iframe.component.html',
    styleUrls: ['./ln-dialog-iframe.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LnDialogIframeComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<LnDialogIframeComponent>,
    ) {
    }

    ngOnInit(): void {
        this.dialogRef.updateSize(
            '100%',
            '100%'
        )
    }

}
