<div class="flex flex-row justify-between ml-4 mr-8 mt-2">
    <div class="flex found">
        <a class="text-lg font-bold colorCafe ">{{ 'realState.found' | translate }}</a>
    </div>
    <div>
        <a class="see-more cursor-pointer" (click)="goToPublishers()">{{ 'home.seeAll' |
            translate }}</a>
    </div>
</div>
<!-- Slider main container -->

<div class="swiper" style="width: 100% !important; padding-top: 1%; padding-bottom: 1%;">
    <!-- Additional required wrapper -->

    <div class="swiper-wrapper pb-4 pt-3 ">
        <!-- Muestra los datos cuando loading es falso -->
        <ng-container *ngIf="!loadingData; else skeleton">
            <div class="swiper-slide" *ngFor="let publisher of arrayAleatorio" style="align-items: center;">
                <mat-card class="custom-card publisherCard" style="cursor: pointer; "
                    (click)="goToRealState(publisher.user_id)">
                    <mat-card-content>
                        <div class="card-content">
                            <div class="image-container">
                                <img style="max-height: 100px;" *ngIf="publisher.logo === '' || publisher.logo === null"
                                    class="imgRealState centered-image"
                                    src="../../../../../assets/images/logo-inmobiliaria-default.png">
                                <img style="max-height: 100px;" *ngIf="publisher.logo !== '' && publisher.logo !== null"
                                    class="imgRealState centered-image" [src]="env + publisher.logo">
                            </div>
    
                            <div class="pt-3" id="publisherAndPriceRow">
                                <div class="text-center">
                                    <span class="name">{{ publisher.name }}</span>
                                </div>
                                <div class="text-center">
                                    <span class="view" style="cursor: pointer;"
                                        (click)="goToRealState(publisher.user_id)">{{ 'realState.properties'
                                        | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
    
    
            <div class="swiper-slide">
                <mat-card class="custom-card publisherCard" (click)="goToPublishers()"
                    style="cursor: pointer; display: flex; justify-content: center; align-items: center;">
                    <mat-card-content>
                        <div class="card-content">
                            <div class="pt-3 " id="publisherAndPriceRow" style="padding-top: auto;">
                                <span class="view" (click)="goToPublishers()" style="cursor: pointer;">{{'realState.viewAll'
                                    | translate }}</span>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </ng-container>
    </div>




    <!-- If we need pagination -->
    <div class="swiper-pagination "></div>
    <!-- If we need navigation buttons -->
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
</div>

 <!-- Muestra el skeleton si loading es verdadero -->
 <ng-template #skeleton>
    <div class="swiper-slide" *ngFor="let i of [].constructor(3);" style="align-items: center;"> 
        <mat-card class="custom-card publisherCard">
            <div class="slideSkeleton">
                <div class="skeleton"></div>
            </div>
        </mat-card>
    </div>
    
    <div class="swiper-slide">
        <mat-card class="custom-card publisherCard" (click)="goToPublishers()"
            style="cursor: pointer; display: flex; justify-content: center; align-items: center;">
            <mat-card-content>
                <div class="card-content">
                    <div class="pt-3 " id="publisherAndPriceRow" style="padding-top: auto;">
                        <span class="view" (click)="goToPublishers()" style="cursor: pointer;">{{'realState.viewAll'
                            | translate }}</span>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    
</ng-template>