import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import {MaterialModule} from "../../modules/material.module";
import {LnFormFieldInputComponent} from "./ln-form-field-input/ln-form-field-input.component";
import {LnFormFieldSelectComponent} from "./ln-form-field-select/ln-form-field-select.component";
import {LnCheckboxComponent} from "./ln-checkbox/ln-checkbox.component";
import {LnButtonLoadingComponent} from './ln-button-loading/ln-button-loading.component';
import {LnButtonComponent} from './ln-button/ln-button.component';
import {LnFormFieldPasswordComponent} from './ln-form-field-password/ln-form-field-password.component';
import {LnDialogInfoComponent} from './ln-dialog-info/ln-dialog-info.component';
import {LnBackButtonComponent} from './ln-back-button/ln-back-button.component';
import {LnCardComponent} from './ln-card/ln-card.component';
import {LnPriceSliderComponent} from './ln-price-slider/ln-price-slider.component';
import {LnCardMapsComponent} from './ln-card-maps/ln-card-maps.component';
import {LnCardSkeletonComponent} from "./ln-card-skeleton/ln-card-skeleton.component";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {LnPaginatorComponent} from './ln-paginator/ln-paginator.component';
import {RouterLink} from "@angular/router";
import { LnCardServiceComponent } from './ln-card-service/ln-card-service.component';
import { LnFormFieldSelectMultipleComponent } from './ln-form-field-select-multiple/ln-form-field-select-multiple.component';
import { LnFormFieldTelephoneComponent } from './ln-form-field-telephone/ln-form-field-telephone.component';
import { LnSocialMediaComponent } from './ln-social-media/ln-social-media.component';
import { LnTranslateComponent } from './ln-translate/ln-translate.component';
import {TranslateModule} from "@ngx-translate/core";
import { LnPublisherCardComponent } from './ln-publisher-card/ln-publisher-card.component';




const components = [
    LnCheckboxComponent,
    LnFormFieldInputComponent,
    LnFormFieldSelectComponent,
    LnButtonLoadingComponent,
    LnButtonComponent,
    LnFormFieldPasswordComponent,
    LnDialogInfoComponent,
    LnCardComponent,
    LnPriceSliderComponent,
    LnBackButtonComponent,
    LnCardMapsComponent,
    LnCardSkeletonComponent,
    LnCardServiceComponent,
    LnPaginatorComponent,
    LnFormFieldSelectMultipleComponent,
    LnFormFieldTelephoneComponent,
    LnTranslateComponent,
    LnSocialMediaComponent,
    LnPublisherCardComponent

]

@NgModule({
    declarations: [
        ...components,        
    ],
    exports: [
        ...components
    ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        NgOptimizedImage,
        NgxSkeletonLoaderModule,
        RouterLink,
        TranslateModule,
        
    ]
})
export class LnComponentsModule {
}
