import { Component, HostListener, OnInit } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { TranslationService } from "../../services/translation.service";
import { Swiper } from "swiper";
import { PublisherUseCaseService } from "src/app/core/usecase/publisher-use-case.service";
import { Observable } from "rxjs";
import { ConnectionPositionPair } from "@angular/cdk/overlay";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-slide-publisher",
  templateUrl: "./slide-publisher.component.html",
  styleUrls: ["./slide-publisher.component.css"],
})
export class SlidePublisherComponent implements OnInit {
  env = environment.firebaseConfig.storageBucket+'/';

  data: any;
  slideIndex = 0;
  visibleData: any[] = [];
  arrayAleatorio: any[] = [];
  obsValue: Observable<string> | undefined;
  loadingData=true;
  swiper: Swiper | undefined;
  constructor(
    private _router: Router,
    private _translate: TranslationService,
    private publisherUseCaseService: PublisherUseCaseService
  ) {}

  ngOnInit(): void {
   this.loadData();
   
   this._router.events.subscribe(event => {
    this.swiper?.update();
    this.swiper?.slideTo(0);
  });
 
  }

  loadData(){
   
    this.publisherUseCaseService.getPublisher().subscribe(data => {
      this.data=Object.values(data)
      if(this.data.length>6){
      this.obtenerElementosAleatorios();
      this.loadingData = false;
      }
      else{
        this.arrayAleatorio=this.data;
        this.loadingData = false;
      }
       
      setTimeout(() => {
        this.swiperConfig(); // Inicializar el Swiper después de un pequeño retraso
      }, 200);
    });
    this.swiperConfig();
  }


  
  swiperConfig() {
    this.swiper = new Swiper(".swiper", {
      direction: "horizontal",
      slidesPerView: "auto",
      cssMode: true,     
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        
      },
      breakpoints: {
        "@0.00": {
          slidesPerView: "auto",
          centeredSlides: true,
        },
        "@0.75": {
          slidesPerView: "auto",
          centeredSlides: true,
        },
        "@1.00": {
          slidesPerView: 2,
          watchOverflow: true,
          centerInsufficientSlides: true,
        },
        "@1.25": {
          slidesPerView: 3, 
          centerInsufficientSlides: true,
          watchOverflow: true,

        },
        "@1.50": {
          slidesPerView: 4, 
          centerInsufficientSlides: true,
        },
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      updateOnWindowResize: true,
    });
    

  }

  goToRealState(id: string) {
    this._router.navigateByUrl(`real-state/${id}`);
  }
  goToPublishers() {
    this._router.navigateByUrl(`publishers`);
  }

  obtenerElementosAleatorios() {
    
    // Clonar el array original para evitar modificarlo directamente
    
    const copiaArrayOriginal = [...this.data];

    for (let i = 0; i < 7; i++) {
      // Generar un índice aleatorio dentro del rango válido
      const indiceAleatorio = Math.floor(Math.random() * copiaArrayOriginal.length);

      // Agregar el elemento aleatorio al array aleatorio
      this.arrayAleatorio.push(copiaArrayOriginal.splice(indiceAleatorio, 1)[0]);
    }
  }
}
