import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FilterValues} from "../../meta/filter-side-menu-meta";

@Component({
    selector: 'ln-filter-additional-services',
    templateUrl: './ln-filter-additional-services.component.html',
    styleUrls: ['./ln-filter-additional-services.component.css']
})
export class LnFilterAdditionalServicesComponent {

    @Input() filtersServicesItem!: FilterValues;

    @Output() selected = new EventEmitter<FilterValues>();
    @Output() removed = new EventEmitter<FilterValues>();

    onChanged({checked}: any, item: FilterValues) {
        checked ? this._onSelected(item) : this._onRemoved(item);
    }

    private _onSelected(item: FilterValues) {
        this.selected.emit(item);
    }

    private _onRemoved(item: FilterValues) {
        this.removed.emit(item);
    }

}
