import {NgModule} from '@angular/core';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {RouterModule, RouterOutlet} from "@angular/router";
import {GalleryModule} from "ng-gallery";
import {LightboxModule} from "ng-gallery/lightbox";
import {TranslateModule, TranslatePipe} from '@ngx-translate/core';

import {NavLayoutComponent} from './components/layouts/nav-layout/nav-layout.component';
import {AuthLayoutComponent} from './components/layouts/auth-layout/auth-layout.component';
import {MaterialModule} from "./modules/material.module";
import {LnComponentsModule} from "./components/ln-customs-material-components/ln-components.module";
import {HeaderComponent} from "./components/header/header.component";
import {FooterComponent} from './components/footer/footer.component';
import {LnAlertComponent} from './components/alerts/ln-alert/ln-alert.component';
import {SearchBarComponent} from "./components/search-bar/search-bar.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AuthStateDirectiveDirective} from './directives/auth-state-directive.directive';
import {AuthStateAnonymousDirective} from "./directives/auth-state-anonymous-directive";
import {SearchBarMobileComponent} from './components/search-bar-mobile/search-bar-mobile.component';
import {LnGoogleMapModule} from "./ln-google-map/ln-google-map.module";
import {SearchHeaderMobileComponent} from './components/search-header-mobile/search-header-mobile.component';
import {SortPostsComponent} from "./components/sort-posts/sort-posts.component";
import {ImagesGalleryComponent} from './components/images-gallery/images-gallery.component';
import {LnIframeComponent} from './components/ln-iframe/ln-iframe.component';
import {LnDialogIframeComponent} from "./components/ln-dialog-iframe/ln-dialog-iframe.component";
import { ServicesFormComponent } from './components/services-form/services-form.component';
import {ContactFormRepository} from "../core/repositories/contact-form.repository";
import {ContactFormWebRepository} from "../data/repository/contact-form.web-repository";
import {ContactFormUseCaseService} from "../core/usecase/contact-form-use-case.service";
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { LnDialogLoginRegistSessionComponent } from './components/ln-dialog-login-regist-session/ln-dialog-login-regist-session.component';
import {LnDialogComponent} from "./components/ln-dialog/ln-dialog.component";
import { FiltersMenuMobileComponent } from './components/filters-menu-mobile/filters-menu-mobile.component';
import { SlidePublisherComponent } from './components/slide-publisher/slide-publisher.component';


const components = [
    AuthLayoutComponent,
    NavLayoutComponent,
    LnAlertComponent,
    HeaderComponent,
    FooterComponent,
    SearchBarComponent,
    SearchBarMobileComponent,
    AuthStateDirectiveDirective,
    AuthStateAnonymousDirective,
    SearchHeaderMobileComponent,
    SortPostsComponent,
    ImagesGalleryComponent,
    LnIframeComponent,
    LnDialogIframeComponent,
    ServicesFormComponent,
    ContactFormComponent,    
    LnDialogLoginRegistSessionComponent,
    LnDialogComponent,
    FiltersMenuMobileComponent,
    SlidePublisherComponent
]

const modules = [
    LnComponentsModule,
    ClipboardModule,
    MaterialModule,
    TranslateModule,
    LnGoogleMapModule
]

@NgModule({
    declarations: [...components, SlidePublisherComponent, ],
    exports: [...components, ...modules],
    imports: [
        CommonModule,
        RouterOutlet,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        GalleryModule,
        LightboxModule,
        ...modules
    ],
    providers: [
        CurrencyPipe,
        {provide: ContactFormRepository, useClass: ContactFormWebRepository},
        ContactFormUseCaseService,
        TranslatePipe
    ]
})
export class SharedModule {
}
