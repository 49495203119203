import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {PropertyTypesPageActions, PropertyTypesApiActions, PropertyTypesSelectors} from '../index';
import {EMPTY, exhaustMap, map, withLatestFrom} from "rxjs";
import {Store} from "@ngrx/store";
import {PropertyTypeUseCaseService} from "../../core/usecase/property-type-use-case.service";


@Injectable()
export class PropertyTypesEffects {

    constructor(
        private actions$: Actions,
        private _propertyTypeUseCaseService: PropertyTypeUseCaseService,
        private _store: Store
    ) {
    }

    loadAll$ = createEffect(() => this.actions$.pipe(
        ofType(PropertyTypesPageActions.init),
        withLatestFrom(this._store.select(PropertyTypesSelectors.propertyTypes)),
        exhaustMap((initAction) => {
            return this._propertyTypeUseCaseService
                .get()
                .pipe(
                    map((propertyTypes) => PropertyTypesApiActions.loadAllPropertyTypes({propertyTypes}))
                )
        })
    ))
}
