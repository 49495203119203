<footer class="p-4 bg-white shadow md:p-6 footer">
    <div class="flex flex-col lg:flex-row justify-between">
        <div class="">
            <div class="flex-row">
                <div class="flex">
                    <a href="#" class="flex mb-5 mr-4">
                        <img src="../../../../assets/icons/logo.svg" class="mr-3" class="logoPortal" alt="Logo" />
                    </a>
                    <!-- <a href="#" class="flex mt-1.5 mb-5">
                        <img src="../../../../assets/icons/logoProperties.svg" class="mr-3" alt="Logo" />
                    </a> -->
                </div>
               
                <p class="max-w-lg mb-3 text-[#372121] font-normal text-[16px] leading-[25px]">
                    {{'footer.text1' | translate}} <br>
                    {{'footer.text2' | translate}}
                </p>
            </div>
        </div>
        
        <div class="servicesDiv">
            <ul class="flex flex-wrap mb-6 text-sm text-[#372121] sm:mb-[27px]">
                <li>
                    <a routerLink="/contact-page-form" class="mr-4 md:mr-6"> {{'footer.linkContact' | translate}}</a>
                </li>
                <li>
                    <a routerLink="/blog" class="mr-4 md:mr-6">Blog</a>
                </li>
                <li>
                    <a routerLink="/services" class="mr-4 md:mr-6 ">{{'footer.linkServices' | translate}}</a>
                </li>
                <li>
                    <a routerLink="/us" class="mr-4 md:mr-0">{{'footer.linkUs' | translate}}</a>
                </li>
                <!-- <li>
                    <a routerLink="/services-form">{{'footer.linkPublishProperty' | translate}}</a>
                </li> -->
            </ul>
            <!-- seccion de ciudades mobile -->
            <!-- seccion de ciudades mobile -->
            <div class="flex lg:hidden text-[#372121] leading-[16.41px]">
              <app-city-menu (citySelected)="onCitySelected($event)" [space]="'space-x-2'" [text]="'text-[14px]'"></app-city-menu>
            </div>
    
          <!-- seccion de ciudades desktop -->
           <div class="hidden lg:flex leading-[18.75px] text-[#372121] justify-end">
               <app-city-menu (citySelected)="onCitySelected($event)" [space]="'space-x-3'" [text]="'text-[16px]'"></app-city-menu>
           </div>
              
            <div class="icons lg:justify-end justify-start">
                <a href="https://wa.me/+50245085136" class="pr-4">
                    <img class="icon" src="../../../../assets/icons/whatsapp.svg" alt="" >
                </a>
                <a href="https://www.instagram.com/lunanueva.portal/">
                    <img class="icon" src="../../../../assets/icons/instagram.svg" alt="" >
                </a>
            </div>
        </div>
    </div>
    <div class="seiza">
        <a (click)="goToSeizaPage()"><p>{{ 'footer.linkDevelopedBy1' | translate }} <mat-icon>favorite</mat-icon> {{ 'footer.linkDevelopedBy2' | translate }} </p></a>
    </div>
</footer>
