<hr class="mb-4">
<div class="grid grid-cols-2 pl-4 pr-4">
    <mat-icon (click)="matDialogRef.close()">arrow_back</mat-icon>
    <label>{{ 'filtersSideNavMobile.labelAdditionalFilters' | translate }}</label>
</div>
<hr class="mt-4">
<div class="flex justify-center pr-1 pl-1">
    <ln-filters-side-menu
            class="w-full"
            [label]="'filtersSideNavMobile.labelSelectFilters' | translate ">
    </ln-filters-side-menu>
</div>
<div class="mt-2 mb-2 pl-4 pr-4">
    <ln-button
            [text]="'filtersSideNavMobile.labelApplyFilters' | translate "
            (clickButton)="matDialogRef.close()">
    </ln-button>
</div>
