import {CityRepository} from "../repositories/city.repository";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {CityModel} from "../domain/city-model";

@Injectable({
    providedIn: 'root'
})
export class CityUseCaseService {
    constructor(private repository: CityRepository) {
    }

    get(): Observable<CityModel[]> {
        return this.repository.get();
    }
}
