import {
  Component,
  Input,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { CityUseCaseService } from "../../../core/usecase/city-use-case.service";
import { PropertyTypeUseCaseService } from "../../../core/usecase/property-type-use-case.service";
import { PropertyTypeEntity } from "../../../data/entity/property-type-entity";
import { Observable, startWith, Subscription, switchMap, tap } from "rxjs";
import { FirebaseService } from "../../services/firebase.service";
import { CurrencyPipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { FiltersInterface } from "../../interfaces/filters.interface";
import { CityModel } from "../../../core/domain/city-model";
import { Store } from "@ngrx/store";
import {
  CityPageActions,
  CitySelectors,
  PropertyTypesPageActions,
  PropertyTypesSelectors,
} from "./../../../ngrx/index";
import { TranslationService } from "../../services/translation.service";

@Component({
  selector: "ln-search-bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.css"],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @ViewChild("togglePrice") togglePrice: ElementRef = {} as ElementRef;
  @ViewChild("showRangeDiv") menu: ElementRef = {} as ElementRef;

  @Input() filters: FiltersInterface = {};
  @Input() rangeValues: string = "";
  @Input() selectedMinValue: number = 0;
  @Input() selectedMaxValue: number = 900000;

  cities$!: Observable<CityModel[]>;
  propertyTypes$!: Observable<PropertyTypeEntity[]>;

  changedDefaultValues!: boolean;
  showRange: boolean = false;
  subscriptions: Subscription[] = [];
  minPrice: number = 0;
  maxPrice: number = 900000;

  constructor(
    private _cityService: CityUseCaseService,
    private currencyPipe: CurrencyPipe,
    private firebaseService: FirebaseService,
    private _propertyTypeService: PropertyTypeUseCaseService,
    private renderer: Renderer2,
    private _router: Router,
    private _activateRoute: ActivatedRoute,
    private _store: Store,
    public translate: TranslationService
  ) {
    let suscription = this._activateRoute.queryParams.subscribe((params) => {
      this.filters = {};
      if (params["city"] != undefined) {
        this.filters.city = params["city"];
      }


      if (params["type"] != undefined) {
        this.filters.type = params["type"];
      }

      if (params["purpose"] != undefined) {
        this.filters.purpose = params["purpose"];
      }

      if (params["minPrice"] != undefined) {
        this.selectedMinValue = params["minPrice"];
        this.filters.minPrice = params["minPrice"];
      }

      if (params["maxPrice"] != undefined) {
        this.selectedMaxValue = params["maxPrice"];
        this.filters.maxPrice = params["maxPrice"];
      }
      if (params["rentalTime"] != undefined) {
        this.filters.rentalTime = params["rentalTime"];
      }

      this.changedDefaultValues = params["changedDefaultValues"];
    });

    this.renderer.listen("window", "click", (e: Event) => {
      if (
        e.target !== this.togglePrice.nativeElement &&
        !this.menu.nativeElement.contains(e.target)
      ) {
        this.showRange = false;
        if (this.changedDefaultValues) {
          this.rangeValues = this.currencyPipe.transform(this.selectedMinValue, "USD", "symbol", "1.0") + " - " + this.currencyPipe.transform(this.selectedMaxValue, "USD", "symbol", "1.0");
        }
      }
    });

    this.subscriptions.push(suscription);
  }

  ngOnInit() {
    this._getCities();
    this._getPropertyTypes();
    this.setText();
    this.translate.onLangChange.subscribe((newLanguage) => {
      this.onLanguageChange(newLanguage);
    });
  }
  onLanguageChange(newLanguage: string) {
    this.setText();
  }
  
  ngafterViewInit() {
    this.setMaxValue(this.maxPrice);
    this.setMinValue(this.minPrice);
  }
  ngOnDestroy() {
    if (this.subscriptions)
      this.subscriptions.forEach((subs) => subs.unsubscribe());
  }

  goToSearchInMap() {
    this._getFilters();
    this._router.navigate(["search-map"], {
      queryParams: {
        ...this.filters,
        changedDefaultValues: this.changedDefaultValues,
      },
      queryParamsHandling: "merge",
    });
  }

  setMinValue(value: number) {
    this.selectedMinValue = value;
    this.changedDefaultValues = true;
  }

  setMaxValue(value: number) {
    this.selectedMaxValue = value;
    this.changedDefaultValues = true;
  }

  showRangePrice() {
    this.showRange = !this.showRange;
  }

  searchProperties() {
    if (this.filters.purpose === "Buy") {
      this.filters.rentalTime = undefined;
    }
    if (this.filters.rentalTime) {
      this.filters.purpose = 'Rent';
    }
    this._getFilters();
    if (this.changedDefaultValues) {
      this._router.navigate(["search-posts"], {
        queryParams: {
          ...this.filters,
          changedDefaultValues: this.changedDefaultValues,
        },
        queryParamsHandling: "merge",
      });
    } else {
      this._router.navigate(["search-posts"]);
    }
  }

  sendPriceValues() {
    let valuesReceived = {};
    if (this.changedDefaultValues) {
      valuesReceived = {
        minPrice: this.filters.minPrice,
        maxPrice: this.filters.maxPrice,
      };
    }

    return valuesReceived;
  }

  private _getCities() {
    this._store.dispatch(CityPageActions.init());
    this.cities$ = this._store.select(CitySelectors.cities);
  }

  private _getPropertyTypes() {
    this.propertyTypes$ = this.translate.onLangChange.pipe(
      startWith(() => this.translate.getCurrentLang),
      switchMap((id) => this._propertyTypeService.get()),
      tap(() => this._store.dispatch(PropertyTypesPageActions.init()))
    );
  }

  private _getFilters() {
    if (this.selectedMinValue >= 0 && this.changedDefaultValues) {
      this.filters.minPrice = this.selectedMinValue;
    }

    if (this.selectedMaxValue >= 0 && this.changedDefaultValues) {
      this.filters.maxPrice = this.selectedMaxValue;
    }
  }
  selectedValue: string = "";
  selectedLabel: string = "";
  onlyRent = false;
  rentalTime(value: string) {
    this.selectedValue = "";
    this.filters.rentalTime = value;
    this.selectedValue = value;
    const standardizedValue = value.toLowerCase();
    const translationKey = `rentOptions.${standardizedValue}`;
    this.translate.getTranslation(translationKey).subscribe((translation: string) => { this.selectedLabel = translation; });
    this.rentPurpose = true;
    this.onlyRent = false;
    this.changedDefaultValues = true;
    this.minPrice = 30;
    this.maxPrice = 15000;
    this.setMaxValue(this.maxPrice);
    this.setMinValue(this.minPrice);
    this.selectedMaxValue = this.maxPrice;
    this.selectedMinValue = this.minPrice;
    this.rangeValues = this.currencyPipe.transform(this.selectedMinValue, "USD", "symbol", "1.0") + " - " + this.currencyPipe.transform(this.selectedMaxValue, "USD", "symbol", "1.0");
  }
  rentPurpose: boolean = false;

  setText() {
    if (this.filters.purpose || this.filters.rentalTime) {
      this.rentPurpose = true;
    }
    if (this.filters.rentalTime) {
      const standardizedValue = this.filters.rentalTime!.toLowerCase();
      const translationKey = `rentOptions.${standardizedValue}`;
      this.translate
        .getTranslation(translationKey)
        .subscribe((translation: string) => {
          this.selectedLabel = translation;
        });
    } else if (this.filters.purpose) {
      this.translate
        .getTranslation(`searchBar.text${this.filters.purpose}`)
        .subscribe((translation: string) => {
          this.selectedLabel = translation;
        });
    }
  }

  selectRent() {
    this.changeMinPrice('Rent');
    this.filters.rentalTime = undefined
  }

  changeMinPrice(value: string) {
    this.selectedValue = value;

    if (value === "Buy" || value === "Rent") {
      this.filters.purpose = value;
    }
    this.minPrice = this.filters.purpose === "Rent" ? 30 : 10000;
    this.maxPrice = this.filters.purpose === "Rent" ? 15000 : 900000;
    this.setMaxValue(this.maxPrice);
    this.setMinValue(this.minPrice);
    this.selectedMaxValue = this.maxPrice;
    this.selectedMinValue = this.minPrice;
    this.rangeValues = this.currencyPipe.transform(this.selectedMinValue, "USD", "symbol", "1.0") + " - " + this.currencyPipe.transform(this.selectedMaxValue, "USD", "symbol", "1.0");
    this.translate.getTranslation(`searchBar.text${value}`).subscribe((translation: string) => { this.selectedLabel = translation; })
    this.rentPurpose = true;
  }

  clearFilters() {
    this.filters = {};
    this.selectedValue = "";
    this.minPrice = 0;
    this.maxPrice = 900000;
    this.filters.maxPrice = this.maxPrice;
    this.filters.minPrice = this.minPrice;
    this.selectedMaxValue = this.maxPrice;
    this.selectedMinValue = this.minPrice;

    this.showRange = false;

    this.rangeValues = "";
    this.selectedLabel = "";
    this.rentPurpose = false;
    this.sendPriceValues();
    this.changedDefaultValues = false;
  }

  setChangedDefaultValues() {
    this.changedDefaultValues = true;
  }

  clearPurpose() {
    this.selectedValue = "";
    this.filters.purpose = undefined;
    this.selectedLabel = "";
    this.rangeValues = "";
    this.filters.rentalTime = undefined;
    this.minPrice = 0;
    this.maxPrice = 900000;
    this.filters.maxPrice = this.maxPrice;
    this.filters.minPrice = this.minPrice;
    this.selectedMaxValue = this.maxPrice;
    this.selectedMinValue = this.minPrice;
    this.sendPriceValues();
    if (!this.filters.type && !this.filters.city) {
      this.changedDefaultValues = false;
    }
    this.rentPurpose = false;
  }
}
