import {AbstractControl} from "@angular/forms";

export function passwordValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const validPassword: RegExp = /^(?=.*[A-Z])(?=.*[0-9]).{8,}$/;

  const value = control.value?.toString() || '';
  const valid = validPassword.test(value);
  return valid ? null : {'password-invalid': true};
}

