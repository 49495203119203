import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {PropertyPostRepository} from "../repositories/property-post.repository";
import {FiltersInterface} from "../../shared/interfaces/filters.interface";
import {PropertyPostListInterface} from "../../shared/interfaces/property-post-list.interface";
import {PropertyPostModel} from "../domain/property-post-model";
import {FavoritePostModel} from "../domain/favorite-post-model";

@Injectable({
    providedIn: 'root'
})
export class PropertyPostUseCaseService {
    constructor(private repository: PropertyPostRepository) {
    }

    get(filters?: FiltersInterface): Observable<PropertyPostListInterface> {
        return this.repository.get(filters);
    }

    getPostDetails(id: string): Observable<PropertyPostModel> {
        return this.repository.getPostDetails(id);
    }

    markPostAsFavorite(postFavorite: FavoritePostModel): Observable<any> {
        return this.repository.markPostAsFavorite(postFavorite);
    }

    getFavoriteUserPosts(): Observable<PropertyPostModel[]> {
        return this.repository.getFavoriteUserPosts();
    }
}
