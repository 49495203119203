<mat-card class=" custom-card"  [style.height.px]="height" [routerLink]="[linkDetail, id]" style="cursor: pointer;">
    <img mat-card-image class="custom-img " [ngSrc]="image" width="100" height="100" priority>
    <mat-card-content>
        <div class="w-[339px] min-h-[200px] mx-auto">
            <div class="mt-[15px]">
                <p class="a-secondary-color text-2xl font-medium">{{ title | translate }}</p>
            </div>
            <div *ngIf="content===false">
                <p class="text-[15px] font-medium custom-text custom-text">{{ textOne | translate}}</p>
                <p class="text-[15px] custom-text font-normal">{{ textTwo | translate }}</p>
            </div>
            <div *ngIf="content===true" style="display: contents;">
                <a class="text-[15px] font-medium custom-text custom-text">{{ textOne | translate}}</a>
                <a class="text-[15px] custom-text font-normal"> {{ textTwo | translate }}</a>
            </div>
           
        </div>
        <div class="flex justify-between mt-[20px]">
            <p *ngIf="dateInformation" class="text-[#B79086] text-sm font-normal min-w-[180px]">
                {{dateInformation}}
            </p>
            <p class="w-full a-secondary-color text-right text-sm font-medium">
                <a [routerLink]="[linkDetail, id]">{{ 'services.textMore' | translate }}</a>
            </p>
        </div>

    </mat-card-content>
</mat-card>