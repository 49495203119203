import { Component } from '@angular/core';

@Component({
  selector: 'ln-card-skeleton',
  templateUrl: './ln-card-skeleton.component.html',
  styleUrls: ['./ln-card-skeleton.component.css']
})
export class LnCardSkeletonComponent {

}
