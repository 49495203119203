import {TranslateService} from "@ngx-translate/core";

export class FilterSideMenuMeta {

    textData = {};
    constructor(private translate: TranslateService) {
        this.translate.get('filtersSideMenu').subscribe((data) => {
            this.textData = data;
        });
    }

    static readonly controlsKey: ControlsKey = {
        type: 'type',
        purpose: 'purpose',
        typeOfRent: 'typeOfRent',
        bedrooms: 'bedrooms',
        bathrooms: 'bathrooms',
        parking: 'parking',
        condition: 'condition',
        tour: 'tour',
        vr: 'vr',
        postedToday: 'postedToday',
        petFriendly: 'petFriendly',
        terrace: 'terrace',
        garden: 'garden'
    }

    static readonly controlsKeyAdditionalServices = {
        tour: 'tour',
        vr: 'vr',
        postedToday: 'postedToday'
    }

    static readonly controlsKeyImmutables: ControlsKeyImmutables = {
        type: 'type',
        purpose: 'purpose'
    }

    static readonly typeOfRent: FilterValuesContainer = {
        name: 'filtersSideMenu.textTypeOfLease',
        filterValue: [
            {
                key: 0,
                value: 'filtersSideMenu.textTraditional',
                filterType: FilterSideMenuMeta.controlsKey.typeOfRent
            },
            {
                key: 1,
                value: 'Online',
                filterType: FilterSideMenuMeta.controlsKey.typeOfRent
            }
        ]
    }

    /*static bedrooms: FilterValuesContainer = {
        name: 'filtersSideMenu.textBedrooms',
        filterValue: [
            {
                key: 0,
                value: 'filtersSideMenu.textStudio',
                filterType: FilterSideMenuMeta.controlsKey.bedrooms
            },
            {
                key: 1,
                value: '1 ' + 'filtersSideMenu.textBedroom',
                filterType: FilterSideMenuMeta.controlsKey.bedrooms
            },
            {
                key: 2,
                value: '2 ' + 'filtersSideMenu.textBedrooms',
                filterType: FilterSideMenuMeta.controlsKey.bedrooms
            },
            {
                key: 3,
                value: '3 ' + 'filtersSideMenu.textBedrooms',
                filterType: FilterSideMenuMeta.controlsKey.bedrooms
            },
            {
                key: 4,
                value: '4 ' + 'filtersSideMenu.textBedrooms' + 'o más',
                filterType: FilterSideMenuMeta.controlsKey.bedrooms
            }
        ]
    }

    static readonly bathrooms: FilterValuesContainer = {
        name: 'filtersSideMenu.textBathrooms',
        filterValue: [
            {
                key: 1,
                value: '1 baño',
                filterType: FilterSideMenuMeta.controlsKey.bathrooms
            },
            {
                key: 2,
                value: '2 baños',
                filterType: FilterSideMenuMeta.controlsKey.bathrooms
            },
            {
                key: 3,
                value: '3 baños',
                filterType: FilterSideMenuMeta.controlsKey.bathrooms
            },
            {
                key: 4,
                value: '4 baños o más',
                filterType: FilterSideMenuMeta.controlsKey.bathrooms
            }
        ]
    }

    static readonly parking: FilterValuesContainer = {
        name: 'Parking',
        filterValue: [
            {
                key: 0,
                value: 'filtersSideMenu.textNoParking',
                filterType: FilterSideMenuMeta.controlsKey.parking
            },
            {
                key: 1,
                value: '1 Parking',
                filterType: FilterSideMenuMeta.controlsKey.parking
            },
            {
                key: 2,
                value: '2 Parking',
                filterType: FilterSideMenuMeta.controlsKey.parking
            },
            {
                key: 3,
                value: '3 Parking',
                filterType: FilterSideMenuMeta.controlsKey.parking
            },
            {
                key: 4,
                value: '4 Parking o más',
                filterType: FilterSideMenuMeta.controlsKey.parking
            }
        ]
    }
    */

    static readonly condition: FilterValuesContainer = {
        name: 'filtersSideMenu.textCondition',
        filterValue: [
            {
                key: 1,
                value: 'filtersSideMenu.textNew',
                filterType: FilterSideMenuMeta.controlsKey.condition
            },
            {
                key: 0,
                value: 'filtersSideMenu.textUsed',
                filterType: FilterSideMenuMeta.controlsKey.condition
            }
        ]
    }

    static readonly tour: FilterValuesContainer = {
        name: 'Tour 360º',
        filterValue: [
            {
                key: 1,
                value: 'Tour 360º',
                filterType: FilterSideMenuMeta.controlsKey.tour
            }
        ]
    }

    static readonly vr: FilterValuesContainer = {
        name: 'general.textVirtualReality',
        filterValue: [
            {
                key: 1,
                value: 'general.textVirtualReality',
                filterType: FilterSideMenuMeta.controlsKey.vr
            }
        ]
    }

    static readonly postedToday: FilterValuesContainer = {
        name: 'filtersSideMenu.textPublishedToday',
        filterValue: [
            {
                key: 1,
                value: 'filtersSideMenu.textPublishedToday',
                filterType: FilterSideMenuMeta.controlsKey.postedToday
            }
        ]
    }

    static readonly petFriendly: FilterValuesContainer = {
        name: 'Pet friendly',
        filterValue: [
            {
                key: 1,
                value: 'Pet friendly',
                filterType: FilterSideMenuMeta.controlsKey.petFriendly
            }
        ]
    }

    static readonly terrace: FilterValuesContainer = {
        name: 'filtersSideMenu.textTerrace',
        filterValue: [
            {
                key: 1,
                value: 'filtersSideMenu.textTerrace',
                filterType: FilterSideMenuMeta.controlsKey.terrace
            }
        ]
    }

    static readonly garden: FilterValuesContainer = {
        name: 'filtersSideMenu.textGarden',
        filterValue: [
            {
                key: 1,
                value: 'filtersSideMenu.textGarden',
                filterType: FilterSideMenuMeta.controlsKey.garden
            }
        ]
    }

}

interface ControlsKey {
    type: FormFilterType;
    purpose: FormFilterType;
    typeOfRent: FormFilterType;
    bedrooms: FormFilterType;
    bathrooms: FormFilterType;
    parking: FormFilterType;
    condition: FormFilterType;
    tour: FormFilterType,
    vr: FormFilterType,
    postedToday: FormFilterType,
    petFriendly: FormFilterType,
    terrace: FormFilterType,
    garden: FormFilterType
}

interface ControlsKeyImmutables {
    type: string;
    purpose: string;
}

interface FilterValuesContainer {
    filterValue: FilterValues[];
    name: string;
}

export interface FiltersChips {
    formControl: string;
    id: number | string;
    isImmutable: boolean;
    value: string;
}

export interface FilterValues {
    cant?: number;
    filterType: FormFilterType;
    isSelected?: boolean;
    key: number;
    value: string;
}

export type ControlsKeyImmutableType = 'type' | 'purpose';

export type FormFilterType =
    'type'
    | 'purpose'
    | 'typeOfRent'
    | 'bedrooms'
    | 'bathrooms'
    | 'parking'
    | 'condition'
    | 'tour'
    | 'vr'
    | 'postedToday'
    | 'petFriendly'
    | 'terrace'
    | 'garden'

export type PurposeType = 'Rent' | 'Buy';

export type FilterContainerType = FilterValuesContainer;
