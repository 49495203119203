import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";

import {ContactFormUseCaseService} from "../../../core/usecase/contact-form-use-case.service";
import {ModalService} from "../../services/modal.service";
import {DialogService} from "../../services/dialog.service";
import {CaptchaService} from "../../services/captcha.service";
import {Location} from "@angular/common";


export interface ContactForm {
    name: FormControl,
    phone: FormControl,
    email: FormControl,
    message: FormControl
    copy: FormControl
}

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent {

    telephonePrefix: string = ''

    @Input() title: string = 'Contáctanos';
    @Input() message: string = '';
    @Output() contactData = new EventEmitter<Object>();
    error = 'Campo requerido *';
    

    formGroup: FormGroup<ContactForm> = new FormGroup(
        {
            name: new FormControl('', Validators.required),
            phone: new FormControl('', [Validators.required, Validators.pattern("^[0-9]+$")]),
            message: new FormControl(this.message, Validators.required),
            email: new FormControl('', [Validators.required, Validators.email]),
            copy: new FormControl(false)
        }
    );

    constructor(
        private _captchaService: CaptchaService,
        private _contactFormUseCaseService: ContactFormUseCaseService,
        private _modal: ModalService,
        private _dialogService: DialogService,
        private _location: Location
    ) {
    }

    ngOnInit() {
        this.formGroup.controls.message.setValue(this.message);
       
    }

    async sendEmails() {
        const captcha: string = await this._captchaService.getCaptchaToken()
        if (!this.formGroup.controls.message.value)
            this.formGroup.controls.message.setValue(this.message);

            this._contactFormUseCaseService.currentTelephonePrefix.subscribe((prefix) => {                
                
                this.telephonePrefix = prefix;
                this.formGroup.value.phone = `+${this.telephonePrefix}-${this.formGroup.value.phone}`;
                this.contactData.emit({...this.formGroup.value, captcha});
                
            });        
        console.log(this.formGroup.value)
    }

    checkBoxChanged(event: any) {
        this.formGroup.controls.copy.setValue(event.valueOf())
    }

    goBack() {
        this._location.back();
    }
}
