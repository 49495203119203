import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription, take} from "rxjs";
import {ContactFormUseCaseService} from "../../core/usecase/contact-form-use-case.service";
import {ModalService} from "../../shared/services/modal.service";
import {DialogService} from "../../shared/services/dialog.service";
import {Router} from "@angular/router";
import {ContactFormTexts} from "../../shared/components/services-form/contact-form-texts";
import {ContactFormModel} from "../../core/domain/contact-form-model";

@Component({
  selector: 'app-contact-page-form',
  templateUrl: './contact-page-form.component.html',
  styleUrls: ['./contact-page-form.component.css']
})
export class ContactPageFormComponent implements OnInit, OnDestroy{

  subscriptions: Subscription[] = [];

  constructor(
      private _contactFormUseCaseService: ContactFormUseCaseService,
      private _modal: ModalService,
      private _dialogService: DialogService,
      private _router: Router) {
  }

  sendEmails($item: any) {
    const { captcha, ...rest } = $item;

    this.subscriptions.push(this._contactFormUseCaseService.postEmailContact(<ContactFormModel>{...rest}, captcha)
        .subscribe({
          next: (data) => {
            this._dialogService.openInfoDialog(ContactFormTexts.infoDialog.buttonText, '', ContactFormTexts.infoDialog.textBody, ContactFormTexts.infoDialog.textHeader)
                .pipe(take(1))
                .subscribe(res => this.goToHomePage())
          },
          error: () => {
            this._modal.show('contactPageForm.textErrorOccur', 'contactPageForm.textErrorExplanation', "ERROR", 5)
          }
        })
    );
  }

  ngOnDestroy() {
    if (this.subscriptions)
      this.subscriptions.forEach(subs => subs.unsubscribe());
  }

  goToHomePage(): void {
    this._router.navigate(['/']);
  }

  ngOnInit(): void {
  }

}
