import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {from, Observable, switchMap} from 'rxjs';

import {FirebaseService} from "../services/firebase.service";
import {environment} from "../../../environments/environment";


@Injectable()
export class Interceptor implements HttpInterceptor {

    constructor(
        private _firebase: FirebaseService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!request.url.startsWith(environment.baseURL)) {
            return next.handle(request)
        }

        const client_id = localStorage.getItem('client_id')?.toString() ?? '';
        const language = localStorage.getItem('language')?.toString() ?? 'es';

        return from(this._firebase.getToken()).pipe(switchMap(token_firebase => {
            const headers = request.clone(
                {
                    setHeaders: {
                        Authorization: `Bearer ${token_firebase}`,
                        'X-Language': language
                    },
                    setParams: {
                        'client-id': client_id
                    }
                }
            );
            return next.handle(headers);
        }))
    }
}

