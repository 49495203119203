import {Injectable} from '@angular/core';
import {getAuth, onAuthStateChanged} from '@angular/fire/auth';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot,} from '@angular/router';


@Injectable({
    providedIn: 'root',
})
export class UserIsLogedIn implements CanActivate {

    constructor(private router: Router) {
    }

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve, reject) => {
            onAuthStateChanged(getAuth(), (user) => {
                if (user && user.emailVerified) {
                    user.getIdToken()
                        .then(token => {
                            if (token) {
                                resolve(true);
                            } else {
                                reject(this.router.navigateByUrl('/sessions/signin'));
                            }
                        }).catch(() => reject(this.router.navigateByUrl('/sessions/signin')));
                } else {
                    reject(this.router.navigateByUrl('/sessions/signin'));
                }
            });
        });
    }
}

@Injectable({
    providedIn: 'root',
})
export class UserNotLogedIn implements CanActivate {

    constructor(private router: Router) {
    }

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            onAuthStateChanged(getAuth(), async (user) => {
                if (user && !user.isAnonymous && user.emailVerified) {
                    reject(this.router.navigateByUrl(''));
                } else {
                    resolve(true);
                }
            });
        });
    }

}
