import { Component } from '@angular/core';
import {Subscription, take} from "rxjs";
import {ContactFormUseCaseService} from "../../core/usecase/contact-form-use-case.service";
import {ModalService} from "../../shared/services/modal.service";
import {DialogService} from "../../shared/services/dialog.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ContactFormModel} from "../../core/domain/contact-form-model";
import {ContactFormTexts} from "../../shared/components/services-form/contact-form-texts";

@Component({
  selector: 'app-contact-seller-page-form',
  templateUrl: './contact-seller-page-form.component.html',
  styleUrls: ['./contact-seller-page-form.component.css']
})
export class ContactSellerPageFormComponent {
  subscriptions: Subscription[] = [];
  title: string = 'Contacta al vendedor';
  message: string = 'Hola,¿Aún sigue disponible?';
  postId: string = '';

  constructor(
      private _contactFormUseCaseService: ContactFormUseCaseService,
      private _modal: ModalService,
      private _dialogService: DialogService,
      private _router: Router,
      private _activateRoute: ActivatedRoute,) {

    let suscription = this._activateRoute.queryParams.subscribe(params => {
      this.postId = params['id'];
    });
    this.subscriptions.push(suscription);
  }

  sendEmails($item: any) {
    const { captcha, ...rest } = $item;

    this.subscriptions.push(this._contactFormUseCaseService.postEmailSellerContact(this.postId, <ContactFormModel>{...rest}, captcha)
        .subscribe({
          next: (data) => {
            this._dialogService.openInfoDialog(ContactFormTexts.infoDialog.buttonText, '', ContactFormTexts.infoDialog.textBody, ContactFormTexts.infoDialog.textHeader)
                .pipe(take(1))
                .subscribe(res => this.goToHomePage())
          },
          error: () => {
            this._modal.show('contactPageForm.textErrorOccur', 'contactPageForm.textErrorExplanation', "ERROR", 5)
          }
        })
    );
  }

  ngOnDestroy() {
    if (this.subscriptions)
      this.subscriptions.forEach(subs => subs.unsubscribe());
  }

  goToHomePage(): void {
    this._router.navigate(['/']);
  }

  ngOnInit(): void {
  }
}
