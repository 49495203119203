
<div class="searchDivMobile pl-5 pr-5" >
    <mat-accordion>
        <mat-panel-title class="tittle">
            {{ 'searchBar.labelChooseOption' | translate }}
        </mat-panel-title>

        <div style="display: flex; align-items: center;">
            <mat-form-field class="example-full-width" appearance="outline" style=" padding-top: 2%; align-self: center;" >
                <mat-select [(value)]="this.filters.purpose" 
                placeholder="{{ selectedLabel  || ('searchBar.placeHolderLike' | translate) }}">
                <mat-option [value]="" (click)="clearPurpose()">---</mat-option>
                <mat-optgroup value="Rent"> {{ 'searchBar.textRent' | translate }}
                    <mat-option  value="'ONE_MONTH'"
                        (click)="rentalTime('ONE_MONTH')">{{'rentOptions.one_month'| translate }}</mat-option>
                    <mat-option  value="'THREE_MONTHS'"
                        (click)="rentalTime('THREE_MONTHS')">{{'rentOptions.three_months'| translate }}</mat-option>
                    <mat-option  value="'MORE_THAN_THREE_MONTHS'"
                        (click)="rentalTime('MORE_THAN_THREE_MONTHS')">{{'rentOptions.more_than_three_months'|
                        translate }}</mat-option>
                    <mat-option  value="'ONE_YEAR'"
                        (click)="rentalTime('ONE_YEAR')">{{'rentOptions.one_year'| translate }}</mat-option>
                </mat-optgroup>
                <mat-option value="Buy" (click)="changeMinPrice('Buy')"> {{ 'searchBar.textBuy' | translate }} </mat-option>
                </mat-select>
            </mat-form-field>

                <img src="../../../../assets/icons/filter.svg" (click)="showFilters()" style="align-self: center; padding-left: 2%;">   
        </div>
    </mat-accordion>

    
</div>
<hr>


<div class="grid searchDivMobile">
    <div class="grid grid-cols-2 gap-3 pt-5 pb-5">
        <ln-button [color]="'warn'" [icon]="'search'" [text]="'searchBar.btnSearch' | translate"
            (click)="searchProperties()">
        </ln-button>
        <ln-button [color]="'accent'" [text]="'searchBar.btnSearchInMap' | translate" (clickButton)="goToSearchInMap()">
        </ln-button>
    </div>
</div>