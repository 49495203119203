import {Component, inject, Input} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {FilterSidenavMobileComponent} from "../../../views/nav/filter-sidenav-mobile/filter-sidenav-mobile.component";
import {take} from "rxjs";

@Component({
    selector: 'ln-search-header-mobile',
    templateUrl: './search-header-mobile.component.html',
    styleUrls: ['./search-header-mobile.component.css']
})
export class SearchHeaderMobileComponent {

    private _matDialog = inject(MatDialog);

    @Input() back: string = '/';
    @Input() label: string = '';

    showFilters() {
        this._matDialog.open(FilterSidenavMobileComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
            disableClose: true,
        }).afterClosed()
            .pipe(take(1))
            .subscribe();
    }

}
