import {APP_INITIALIZER, NgModule, isDevMode, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {getAuth, provideAuth} from "@angular/fire/auth";
import {getApp, initializeApp, provideFirebaseApp} from "@angular/fire/app";
import {getStorage, provideStorage} from "@angular/fire/storage";
import { provideAnalytics, getAnalytics } from "@angular/fire/analytics";
import {getFirestore, provideFirestore} from "@angular/fire/firestore";
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClient} from "@angular/common/http";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from "../environments/environment";
import {Interceptor} from "./shared/interceptors/token.interceptor";
import {SharedModule} from "./shared/shared.module";
import {FormsModule} from "@angular/forms";
import {FirebaseService} from "./shared/services/firebase.service";
import {from, Observable} from "rxjs";
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { ServicesPageFormComponent } from './views/services-page-form/services-page-form.component';
import { ContactPageFormComponent } from './views/contact-page-form/contact-page-form.component';
import { ContactSellerPageFormComponent } from './views/contact-seller-page-form/contact-seller-page-form.component';
import { TranslateModule} from '@ngx-translate/core';
import { register } from 'swiper/element/bundle';



register();

function initializeAppFactory(firebaseService: FirebaseService): () => Observable<any> {
    return () => from(firebaseService.initApp());
}


@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent,
        ServicesPageFormComponent,
        ContactPageFormComponent,
        ContactSellerPageFormComponent,
        
      
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideAuth(() => getAuth(getApp())),
        provideFirestore(() => getFirestore(getApp())),
        provideStorage(() => getStorage(getApp())),
        provideAnalytics(() => getAnalytics(getApp())),
        SharedModule,
        FormsModule,
        StoreModule.forRoot({}),
        StoreDevtoolsModule.instrument(
            {maxAge: 25, logOnly: !isDevMode()}
        ),
        EffectsModule.forRoot([]),
        TranslateModule.forRoot()
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [FirebaseService],
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule {
}
