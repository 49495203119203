import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {CurrencyPipe} from "@angular/common";
import {catchError, map, Observable, of, tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {GoogleMap, MapInfoWindow, MapMarker} from '@angular/google-maps';

import {environment} from "../../../../environments/environment";
import {PropertyPostModel} from "../../../core/domain/property-post-model";
import {TranslationService} from "../../services/translation.service";


@Component({
    selector: 'ln-google-map',
    templateUrl: './google-map.component.html',
    styleUrls: ['./google-map.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleMapComponent {

    @ViewChild(MapInfoWindow, {static: false}) infoWindow!: MapInfoWindow;
    @ViewChild(GoogleMap, {static: false}) map!: google.maps.Map;

    @Input() center: google.maps.LatLng | google.maps.LatLngLiteral = {lat: 15.783471, lng: -90.230759};
    @Input() height: string = '600px';
    @Input() posts: PropertyPostModel[] = [];
    @Input() zoom: number = 8;
    @Input() isPropertyDetails: boolean = false;

    @Output() changedBounds = new EventEmitter<google.maps.LatLngBounds | undefined>();

    apiLoaded$!: Observable<boolean>;

    postSelected!: PropertyPostModel;

    infoWindowsOptions: google.maps.InfoWindowOptions = {
        disableAutoPan: true
    }
    options: google.maps.MapOptions = {
        maxZoom: 20,
        minZoom: 8,
        streetViewControl: false,
        styles: [
            {
                featureType: 'poi',
                elementType: 'labels',
                stylers: [{visibility: 'off'}]
            }
        ]
    };
    markerOptions: google.maps.MarkerOptions = {
        draggable: false,
        zIndex: 10,
    };

    timer!: NodeJS.Timer;

    constructor(
        private _cdr: ChangeDetectorRef,
        private _currencyPipe: CurrencyPipe,
        private _httpClient: HttpClient,
        private _translate: TranslationService
    ) {
        this._apiLoading();
    }

    closeInfoWindows() {
        this.infoWindow.close();
    }

    getIcon(price: number, displayIcon: boolean) {
        const tagSvgRaw = (price: number) => `
                    <svg width="109" height="32" viewBox="0 0 109 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="108" height="31" rx="7.5" fill="white"/>
                       <text x="40%" y="22"
                             text-anchor="middle" fill="#000000"
                             font-size="14px" font-family="sans-serif" font-weight="bold">
                             ${this._currencyPipe.transform(price, 'USD')}
                       </text>
                    <rect x="0.5" y="0.5" width="108" height="31" rx="7.5" stroke="#79747E"/>
                    <path d="M95.5 24C100.747 24 105 19.7467 105 14.5C105 9.25329 100.747 5 95.5 5C90.2533 5 86 9.25329 86 14.5C86 19.7467 90.2533 24 95.5 24Z" fill="#FFFEFE"/>
                    <path display="${displayIcon ? 'block' : 'none'}" fill-rule="evenodd" clip-rule="evenodd" d="M98.2148 9.96094C97.2463 9.96094 96.3361 10.3376 95.65 11.0236L95.4976 11.176L95.3452 11.0236C94.6592 10.3376 93.749 9.96094 92.7804 9.96094C91.8164 9.96094 90.9063 10.3376 90.2247 11.0191C89.5387 11.7051 89.1621 12.6153 89.1665 13.5793C89.1665 14.5478 89.5477 15.4536 90.2292 16.1395L95.0671 20.9775C95.1838 21.0941 95.3407 21.1569 95.4931 21.1569C95.6456 21.1569 95.8025 21.0986 95.9191 20.982L100.771 16.1486C101.456 15.4625 101.833 14.5523 101.833 13.5883C101.833 12.6198 101.456 11.7096 100.775 11.0236C100.089 10.3376 99.1788 9.96094 98.2148 9.96094Z" fill="#B79086"/>
                    </svg>
                `;

        return this._encodeSVG(tagSvgRaw(price))
    }

    openInfoWindow(marker: MapMarker, post: PropertyPostModel) {
        this.infoWindow.close();
        this.postSelected = post;
        this.infoWindow.open(marker);
        this._cdr.detectChanges();
    }

    refresh() {
        if (this.timer)
            clearInterval(this.timer as any)
        this.timer = setTimeout(() => this.changedBounds.emit(this.map.getBounds()), 750)
    }

    setZIndex(marker: MapMarker, index: number) {
        marker.marker?.setZIndex(index);
        this._cdr.detectChanges();
    }

    trackByFn(index: number, element: PropertyPostModel) {
        return element.id;
    }

    private _apiLoading() {
        const key = environment.googleMapApiKey
        const language = this._translate.getCurrentLang
        this.apiLoaded$ = this._httpClient.jsonp(
            `https://maps.googleapis.com/maps/api/js?key=${key}&language=${language}`,
            'callback'
        )
            .pipe(
                tap(() => this.options.disableDefaultUI = this.isPropertyDetails),
                map(() => true),
                catchError(() => of(false)),
            );
    }

    private _encodeSVG(rawSvgString: string): string {
        const symbols = /[\r\n%#()<>?\[\\\]^`{|}]/g;
        rawSvgString = rawSvgString
            .replace(/'/g, '"')
            .replace(/>\s+</g, '><')
            .replace(/\s{2,}/g, ' ');

        return `data:image/svg+xml;utf-8,${rawSvgString.replace(symbols, encodeURIComponent)}`
    }
}
