export interface City {
    id: number;
    name: string;
    paisImage: string
  }
  
  export const cities: City[] = [
    { id: 1, name: 'Guanacaste', paisImage: '../../assets/countryIcons/costaRica.png'},
    { id: 2, name: 'La Fortuna', paisImage: '../../assets/countryIcons/costaRica.png'},  
    { id: 3, name: 'Antigua Guatemala', paisImage: '../../assets/countryIcons/guatemala.jpg' },
    { id: 4, name: 'Miami', paisImage: '../../assets/countryIcons/usa.png'},
    { id: 5, name: 'Oaxaca', paisImage: '../../assets/countryIcons/mexico.png'},
    { id: 6, name: 'Tulum', paisImage: '../../assets/countryIcons/mexico.png'},
    { id: 7, name: 'Parkside', paisImage: '../../assets/countryIcons/usa.png'},

  ];