import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {Subscription, take} from "rxjs";

import {FirebaseService} from '../../services/firebase.service';
import {AuthService} from "../../services/auth.service";
import {BreakPointService} from "../../services/break-point.service";
import {LnDialogComponent} from "../ln-dialog/ln-dialog.component";
import { CityService } from './services/city.service';



@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {

    @Input() show: boolean = true;
    public showMenu: boolean = false;
    icon = 'menu';
    isLogedin = false;
    subscriptions: Subscription[] = [];
    activeCity: number | null = null;

    constructor(
        private _dialog: MatDialog,
        private router: Router,
        private firebaseService: FirebaseService,
        private auth: AuthService,
        public breakPointService: BreakPointService,
        private cityService: CityService
    ) {
        this.cityService.selectedCity$.subscribe(cityId => {
            this.activeCity = cityId;
          });
    }

    ngOnInit(): void {
        const subscription = this.firebaseService.isAuthenticatedUser().subscribe(user => {
                this.isLogedin = user;
                this.auth.isLogedin = user;
            }
        )
        this.subscriptions.push(subscription);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    public showMenuMobile() {
        this.showMenu = !this.showMenu;
        this.icon = this.showMenu ? 'close' : 'menu';
    }

    public goToHome() {
        this.router.navigateByUrl('/');
    }

    public signOut() {
        this.firebaseService.doLogout();
        this.goToHome()
    }

    publishProperty() {
        this.router.navigateByUrl('services-form');
    }

    onCitySelected(cityId: number) {
        this.activeCity = cityId;
      }

    redirectContact() {
        this.router.navigateByUrl('contact-page-form');
    }

    openDialogCloseSession() {
        this._dialog.open(LnDialogComponent, {
            width: '380px',
            data: {
                title: 'header.textConfirmCloseSession',
                buttonOk: 'header.textCloseSession',
                buttonCancel: 'header.textNoBack'
            }
        })
            .afterClosed()
            .pipe(take(1))
            .subscribe(res => {
                if (res)
                    this.signOut()
            })
    }
}
