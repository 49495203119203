<div class="text-xs">
    <button mat-raised-button
            [color]="color"
            [disabled]="disabled"
            (click)="onClick()"
    >
        <div class="center-div text-center">
            {{ text | translate }} <span matSuffix *ngIf="icon" class="material-icons ml-2"> {{icon}} </span>
        </div>
    </button>
</div>
