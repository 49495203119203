<!-- <div class="flex flex-wrap space-x-0 lg:space-x-3 gap-[8px] lg:gap-0">
    <ng-container *ngFor="let city of cities;">
      <div class="flex items-center mt-4 lg:mt-0"> <img *ngIf="city.paisImage" [src]="city.paisImage" alt="{{ city.name }} Flag" class="h-4 w-4 mr-2">
        <ng-container *ngIf="city.id === 4 || city.id === 7; else otherCities">
          <a *ngIf="city.id === 4" href="{{urlMiami}}" target="_blank" rel="noopener noreferrer"
             class="{{text}} text-[#372121] hover:font-bold hover:underline cursor-pointer">
            {{ city.name }}
          </a>
          <a *ngIf="city.id === 7" href="{{urlParkside}}" target="_blank" rel="noopener noreferrer"
             class="{{text}} text-[#372121] hover:font-bold hover:underline cursor-pointer">
            {{ city.name }}
          </a>
        </ng-container>
        <ng-template #otherCities>
          <a (click)="setActiveCity(city.id, city.name)"
             [ngClass]="{
               'font-bold underline': activeCity === city.id,
               'font-light': activeCity !== city.id,
               'text-[#97B1B9] cursor-not-allowed pointer-events-none': city.id !== 3
             }"
             class="{{text}} hover:underline cursor-pointer">
            {{ city.name }}
          </a>
        </ng-template>
      </div>
      <span class="mt-4 lg:mt-0" *ngIf="city.id !== cities.length">|</span>
    </ng-container>
</div> -->

<mat-form-field>
  <mat-label>{{'cityMenu.text' | translate}}</mat-label>
  <mat-select class="mt-[8px]" [(value)]="activeCity" (selectionChange)="onCityChange($event.value)">
    <mat-option *ngFor="let city of cities" [value]="city.id" [disabled]="city.id !== 3 && city.id !== 4 && city.id !== 7">
      <div class="city-option-content">
        <img *ngIf="city.paisImage" [src]="city.paisImage" alt="{{ city.name }} Flag" class="h-4 w-4">
        <span *ngIf="city.id !== 4 && city.id !== 7">{{ city.name }}</span>
        <span *ngIf="city.id === 4 || city.id === 7">
          <a [href]="city.id === 4 ? urlMiami : urlParkside" target="_blank" rel="noopener noreferrer">
            {{ city.name }}
          </a>
        </span>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
