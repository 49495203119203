import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {FirebaseService} from "../services/firebase.service";
import {from, Observable} from "rxjs";

@Directive({
    selector: '[appAuthAnonymousStateDirective]'
})
export class AuthStateAnonymousDirective {

    @Input() appAuthAnonymousStateDirective!: Observable<any>;
    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private firebase: FirebaseService
    ) {
    }

    ngOnInit() {
        this.appAuthAnonymousStateDirective.subscribe((authUser) => {
            if (!authUser) {
                this.viewContainer.createEmbeddedView(this.templateRef)
            } else {
                this.viewContainer.clear()
            }
        });
    }

}
