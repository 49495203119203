import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {mergeMap, Observable, startWith, Subject, Subscription, take, takeUntil, tap} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";

import {
    ControlsKeyImmutableType,
    FilterContainerType,
    FiltersChips, FilterSideMenuMeta,
    FilterSideMenuMeta as ConfigMeta,
    FilterValues,
    FormFilterType
} from "./meta/filter-side-menu-meta";
import {FiltersInterface} from "../../../shared/interfaces/filters.interface";
import {PropertyTypeEntity} from "../../../data/entity/property-type-entity";
import {PropertyTypesPageActions, PropertyTypesSelectors} from './../../../ngrx/index';
import {TranslationService} from "../../../shared/services/translation.service";


@Component({
    selector: 'ln-filters-side-menu',
    templateUrl: './filters-side-menu.component.html',
    styleUrls: ['./filters-side-menu.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersSideMenuComponent implements OnInit, OnDestroy {

    @Input() label: string = 'filtersSideMenu.textApartmentsForSale';

    filters!: FiltersInterface;
    filtersConfigMeta = ConfigMeta;
    filtersList!: FilterContainerType[];
    filtersServicesList!: FilterValues[];
    filtersValuesFlat!: FilterValues[];

    propertyTypes$!: Observable<PropertyTypeEntity[]>;
    private _disposed$ = new Subject<void>();
    subscriptions: Subscription[] = [];
    sideMenuTexts: any = {};

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _cdr: ChangeDetectorRef,
        private _router: Router,
        private _store: Store,
        private translate: TranslationService
    ) {
    }

    ngOnInit(): void {
        this.translate.onLangChange
            .pipe(
                takeUntil(this._disposed$),
                startWith(() => this.translate.getCurrentLang),
                mergeMap(() => this.translate.getTranslation('filtersSideMenu'))
            )
            .subscribe(data => {
                this.sideMenuTexts = data
                this._createFilterStructure()
            })
        this._getPropertyTypes();
        this._initialize();
    }

    filterWasSelected(item: FilterValues) {
        this.setFilterParams({...this.filters, [item.filterType]: item.key});
        this._cdr.detectChanges();
    }

    filtersWasRemoved(item: FiltersChips) {
        const control = item.formControl as FormFilterType;
        this._removeFilters(control)
    }

    filtersServicesWasRemoved(item: FilterValues) {
        const control = item.filterType as FormFilterType;
        this._removeFilters(control)
    }

    getFiltersSelected() {
        const filters: FiltersChips[] = [];
        for (let controlsKey in this.filters) {
            const id = this.filters[controlsKey as FormFilterType]?.toString() ?? '';
            let isImmutable = false;
            let value = '';

            if (this.filtersConfigMeta.controlsKeyImmutables[controlsKey as ControlsKeyImmutableType]) {
                isImmutable = true;
                value = this._getImmutablesValues(controlsKey as ControlsKeyImmutableType, id)
            } else if (id !== null) {
                //     TODO => la comparacion debe ser con (!== null) porque el id puede ser 0 y no lo tomaria.
                value = this._getFormFieldsControlValues(controlsKey as FormFilterType, id);
            }

            if (value) filters.push(
                {
                    id,
                    value,
                    isImmutable,
                    formControl: controlsKey
                }
            )
        }
        return filters;
    }

    getFiltersList() {
        const filterSelected = this.getFiltersSelected();
        return this.filtersList.filter(el => {
            return el.filterValue.map(fValue => {
                const exists = filterSelected.find(fSelected => (fSelected.formControl === fValue.filterType && +fSelected.id === fValue.key))
                fValue.isSelected = !!exists;
            })
        });
    }

    getFiltersServicesList() {
        return this.filtersServicesList.map(el => {
            const exists = this.filters[el.filterType];
            el.isSelected = !!exists;
            return el;
        });
    }

    setFilterParams(filters: Partial<FiltersInterface>) {
        this._router.navigate([], {
            relativeTo: this._activatedRoute,
            queryParams: filters
        })
    }

    private _createFilterStructure() {
        this.filtersList = [
            // this.filtersConfigMeta.typeOfRent, TODO => NO por el momento.
            {
                name: 'filtersSideMenu.textBedrooms',
                filterValue: [
                    {
                        key: 0,
                        value: 'filtersSideMenu.textStudio',
                        filterType: FilterSideMenuMeta.controlsKey.bedrooms
                    },
                    {
                        key: 1,
                        value: '1 ' + this.sideMenuTexts['textBedroom'],
                        filterType: FilterSideMenuMeta.controlsKey.bedrooms
                    },
                    {
                        key: 2,
                        value: '2 ' + this.sideMenuTexts['textBedrooms'],
                        filterType: FilterSideMenuMeta.controlsKey.bedrooms
                    },
                    {
                        key: 3,
                        value: '3 ' + this.sideMenuTexts['textBedrooms'],
                        filterType: FilterSideMenuMeta.controlsKey.bedrooms
                    },
                    {
                        key: 4,
                        value: '4 ' + this.sideMenuTexts['textBedrooms'] + " " + this.sideMenuTexts['textMore'],
                        filterType: FilterSideMenuMeta.controlsKey.bedrooms
                    }
                ]
            },
            {
                name: 'filtersSideMenu.textBathrooms',
                filterValue: [
                    {
                        key: 1,
                        value: '1 ' + this.sideMenuTexts['textBathroom'],
                        filterType: FilterSideMenuMeta.controlsKey.bathrooms
                    },
                    {
                        key: 2,
                        value: '2 ' + this.sideMenuTexts['textBathrooms'],
                        filterType: FilterSideMenuMeta.controlsKey.bathrooms
                    },
                    {
                        key: 3,
                        value: '3 ' + this.sideMenuTexts['textBathrooms'],
                        filterType: FilterSideMenuMeta.controlsKey.bathrooms
                    },
                    {
                        key: 4,
                        value: '4 ' + this.sideMenuTexts['textBathrooms'] + " " + this.sideMenuTexts['textMore'],
                        filterType: FilterSideMenuMeta.controlsKey.bathrooms
                    }
                ]
            },
            {
                name: 'Parking',
                filterValue: [
                    {
                        key: 0,
                        value: 'filtersSideMenu.textNoParking',
                        filterType: FilterSideMenuMeta.controlsKey.parking
                    },
                    {
                        key: 1,
                        value: '1 Parking',
                        filterType: FilterSideMenuMeta.controlsKey.parking
                    },
                    {
                        key: 2,
                        value: '2 Parking',
                        filterType: FilterSideMenuMeta.controlsKey.parking
                    },
                    {
                        key: 3,
                        value: '3 Parking',
                        filterType: FilterSideMenuMeta.controlsKey.parking
                    },
                    {
                        key: 4,
                        value: '4 Parking ' + " " + this.sideMenuTexts['textMore'],
                        filterType: FilterSideMenuMeta.controlsKey.parking
                    }
                ]
            },
            this.filtersConfigMeta.condition
        ];
        this.filtersServicesList = [
            this.filtersConfigMeta.tour,
            this.filtersConfigMeta.vr,
            this.filtersConfigMeta.postedToday,
            this.filtersConfigMeta.petFriendly,
            this.filtersConfigMeta.terrace,
            this.filtersConfigMeta.garden,
        ].map(el => el.filterValue).flat();

        this._createFilterValuesFlat();
    }

    private _createFilterValuesFlat() {
        this.filtersValuesFlat = this.filtersList.map(el => el.filterValue).flat();
    }

    private _getFormFieldsControlValues(controlsKey: FormFilterType, id: string | number) {
        return this.filtersValuesFlat.find(res => (res.key === +id && res.filterType === controlsKey))?.value ?? '';
    }

    private _getImmutablesValues(controlsKey: ControlsKeyImmutableType, id: string) {
        let response = '';
        if (controlsKey === this.filtersConfigMeta.controlsKeyImmutables.purpose) {
            response = `searchBar.text${id}`
        } else if (controlsKey === this.filtersConfigMeta.controlsKeyImmutables.type) {
            this.propertyTypes$.pipe(
                take(1)
            )
                .subscribe(
                    res => {
                        response = res.find(el => el.id.toString() === id)?.name ?? '';
                    }
                )
        }
        return response;
    }

    private _getPropertyTypes() {
        this._store.dispatch(PropertyTypesPageActions.init());
        this.propertyTypes$ = this._store.select(PropertyTypesSelectors.propertyTypes);
    }

    private _initialize() {
        this._activatedRoute.queryParams
            .pipe(
                takeUntil(this._disposed$),
                tap(filter => {
                    this.filters = filter;
                    this._cdr.detectChanges();
                })
            ).subscribe();
    }

    private _removeFilters(filter: FormFilterType) {
        const {[filter]: oldFilter, ...rest} = this.filters;
        this.setFilterParams(rest);
        this._cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subs => subs.unsubscribe())
    }

}
