<div class="grid grid-cols-2 bckColor p-5 pt-2 pb-2" >
    <div class="mt-2.5">
        <mat-label>{{ 'rangePrice.from' | translate }}</mat-label>
        <mat-form-field class="bckColor" appearance="outline">
            <input #startValue matInput placeholder="0"
                   [ngModel]="startThumbValueInput | currency: 'USD':'symbol':'1.0'"
                   (ngModelChange)="changeRangeStart($event, startValue)">
        </mat-form-field>
    </div>
    <div class="mt-2.5">
        <mat-label>{{ 'rangePrice.to' | translate }}</mat-label>
        <mat-form-field class="bckColor ml-3 mt-5 pr-3" appearance="outline">
            <input #endValue matInput placeholder="0"
                   [ngModel]="endThumbValueInput | currency: 'USD':'symbol':'1.0'"
                   (ngModelChange)="changeRangeEnd($event, endValue)">
        </mat-form-field>
    </div>
    <div class="col-span-2">
        <mat-slider [min]="minValue" [max]="maxValue" class="pr-3">
            <input [value]="0" matSliderStartThumb [ngModel]="startThumbValue"
                   (ngModelChange)="changeInputStart($event)" (change)="emitValues()">
            <input [value]="900000" matSliderEndThumb [(ngModel)]="endThumbValue"
                   (ngModelChange)="changeInputEnd($event)" (change)="emitValues()">
        </mat-slider>
    </div>
</div>
