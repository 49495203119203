import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from "@angular/material/paginator";

@Component({
    selector: 'ln-paginator',
    templateUrl: './ln-paginator.component.html',
    styleUrls: ['./ln-paginator.component.css']
})
export class LnPaginatorComponent implements AfterViewInit {
    @ViewChild('paginator', {static: false}) paginator!: MatPaginator;

    @Input() disabled = false;
    @Input() length = 50;
    @Input() pageSize = 10;
    @Input() pageIndex = 0;
    @Input() showFirstLastButtons = true;

    @Output() changedPage = new EventEmitter<number>();

    handlePageEvent(e: PageEvent) {
        this.changedPage.emit(e.pageIndex);
    }

    ngAfterViewInit(): void {
        this._paginatorIntl();
    }

    private _paginatorIntl() {
        this.paginator._intl.nextPageLabel = 'Siguiente';
        this.paginator._intl.previousPageLabel = 'Anterior';
        this.paginator._intl.firstPageLabel = 'Primera página';
        this.paginator._intl.lastPageLabel = 'Última página';
        this.paginator._intl.getRangeLabel = (
            page: number,
            pageSize: number,
            length: number,
        ) => {
            if (length === 0 || pageSize === 0) {
                return `0 de ${length}`;
            }
            length = Math.max(length, 0);
            const startIndex = page * pageSize;
            const endIndex =
                startIndex < length
                    ? Math.min(startIndex + pageSize, length)
                    : startIndex + pageSize;
            return `${startIndex + 1} - ${endIndex} de ${length}`
        }
    }

}
