import {Component, inject} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'ln-filter-sidenav-mobile',
    templateUrl: './filter-sidenav-mobile.component.html',
    styleUrls: ['./filter-sidenav-mobile.component.css']
})
export class FilterSidenavMobileComponent {

    matDialogRef = inject(MatDialogRef<FilterSidenavMobileComponent>);

}
