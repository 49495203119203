export class ServicesTypes {

    readonly services = [
        {
             "id": 'tour_360',
             "name": "servicesForm.labelTour",
             "es": "Servicios tour 360º",
             "en": "Tour 360º services"
        },
        /*{
            "id": 'video_360',
            "name": "Servicios video 360º"
        },*/
        {
            "id": 'virtual_reality',
            "name": 'servicesForm.labelVirtualReality',
            "es": "Servicios realidad virtual",
            "en": "Virtual reality services"
        },
        {
            "id": 'photography',
            "name": "servicesForm.labelPhotography",
            "es": "Servicios fotografía",
            "en": "Photography services"
        },
        {
            "id": 'legal',
            "name": "servicesForm.labelLegalServices",
            "en": "Legal services",
            "es": "Servicios legales"
        },
        {
            "id": 'sell_property',
            "name": "servicesForm.labelSellProperty",
            "en": "We sell your property",
            "es": "Vendemos tu propiedad"
        },
        {
            "id": 'rent_property',
            "name": "servicesForm.labelRentProperty",
            "en": "We rent your property",
            "es": "Rentamos tu propiedad"
        },
        {
            "id": 'post_property',
            "name": "servicesForm.labelPostProperty",
            "en": "Post your property",
            "es": "Publica tu propiedad"
        },
        {
            "id": 'management_property',
            "name": "servicesForm.labelManagement",
            "en": "Property Management",
            "es": "Administración y gestión de propiedades"
        },
        {
            "id": 'interior_design',
            "name": "servicesForm.labelDesign",
            "en": "Interior Design",
            "es": "Diseño de interiores"
        },
    ]
}
