import {Observable} from 'rxjs';
import {ServicesFormModel} from "../domain/services-form-model";
import {ContactFormModel} from "../domain/contact-form-model";
import {Injectable} from "@angular/core";

@Injectable()
export abstract class ContactFormRepository {
    abstract postEmailServices(form: Partial<ServicesFormModel>, captcha: string): Observable<any>;

    abstract postEmailContact(form: Partial<ContactFormModel>, captcha: string): Observable<any>;

    abstract postEmailSellerContact(postId: string, form: Partial<ContactFormModel>, captcha: string): Observable<any>;
}
