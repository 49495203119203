import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'ln-form-field-select-multiple',
  templateUrl: './ln-form-field-select-multiple.component.html',
  styleUrls: ['./ln-form-field-select-multiple.component.css']
})
export class LnFormFieldSelectMultipleComponent {

  @Input() control: FormControl = new FormControl<any>({});
  @Input() error = 'Campo requerido *';
  @Input() label = 'Label';
  @Input() list: { key: string, value: any }[] = [];

  @Output() onSelect: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  select({value}: any) {
    this.onSelect.emit(value);
  }

}
