import {createReducer, on} from '@ngrx/store';
import {PropertyTypesApiActions} from './../index';
import {PropertyTypeModel} from "../../core/domain/property-type-model";

export const propertyTypesStateKey = 'propertyTypesState';

export interface PropertyTypesState {
    propertyTypes: PropertyTypeModel[]
}

const initialState: PropertyTypesState = {
    propertyTypes: []
}

export const propertyTypesStateReducer = createReducer(
    initialState,
    on(PropertyTypesApiActions.loadAllPropertyTypes, (currentState, action) => ({
        ...currentState,
        propertyTypes: action.propertyTypes
    }))
)
