<div class="custom-card p-0">
    <img mat-card-image class="img-100" [src]="image" width="100" height="100">
    <div class="p-0">
        <div>
            <div id="publisherAndPriceRow" class="grid grid-cols-2 mt-2 flex-d">
                <div class="div-price">
                    <span>
                   {{price | currency}}
                    </span>
                </div>
                <a [routerLink]="['/post-details', id]" id="seeMore" class="underline mr-6"> {{ 'card.labelSeeMore' | translate }} </a>
            </div>
            <div class="col-span-2 mt-4 mr-2 ml-2">
                <div class="grid-rows-4">
                    <span id="detailsRow1" class="grid grid-cols-2 pb-1">
                        <div>
                            <img class="icons" src="../../../../../assets/icons/hotel.svg">
                            <span class="aligned-with-icon">&nbsp;
                                {{ numberOfBedrooms }} {{ numberOfBedrooms > 1 ? ('card.labelBedrooms' | translate) : ('card.labelBedrooms' | translate) }}
                            </span>
                        </div>
                         <div class="ml-4">
                            <img class="icons" src="../../../../../assets/icons/ruler-square.svg">
                            <span class="aligned-with-icon">&nbsp;{{ totalArea }} M</span>
                         </div>
                    </span>
                    <span id="detailsRow2" class="grid grid-cols-2 pb-1">
                        <div>
                            <img class="icons" src="../../../../../assets/icons/car.svg">
                            <span class="aligned-with-icon">&nbsp;{{ numberOfParkings }} Parking</span>
                        </div>
                        <div class="ml-4">
                             <img class="icons" src="../../../../../assets/icons/shower.svg">
                                                    <span class="aligned-with-icon">&nbsp;
                                                        {{ numberOfBathrooms }}
                                                        {{ 'cardMap.labelBathrooms' | translate }}
                                                    </span>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
