import {Observable} from "rxjs";
import {PropertyTypeRepository} from "../repositories/property-type.repository";
import {Injectable} from "@angular/core";
import {PropertyTypeModel} from "../domain/property-type-model";

@Injectable({
    providedIn: 'root'
})
export class PropertyTypeUseCaseService {
    constructor(private repository: PropertyTypeRepository) {
    }
    
    get(): Observable<PropertyTypeModel[]> {
        return this.repository.get();
    }

}
