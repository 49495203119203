<div class="flex gap-x-2 principalDiv justify-items grow">
    <div class="flex flex-col pr-3 matButton margin" style="min-width: 145px;">
        <h4 style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ 'searchBar.labelChooseOption' |
            translate }}</h4>
        <div>
            <button class="btn overflow " mat-button [matMenuTriggerFor]="purpose"
                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                <span *ngIf="rentPurpose===false"
                    style="margin-left: -2%; display: flex; margin-top: -9%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size: 14px; color: #577778; font-weight: 400; font-family: 'Roboto,sans-serif';">{{
                    ('searchBar.placeHolderLike' | translate) }}
                    <img class="ml-3" src="../../../../assets/icons/arrow-down.svg" alt="" style="display: flex;">
                </span>
                <p *ngIf="rentPurpose"
                    style="display: flex; margin-left: -2%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size: 15px; color: rgba(0, 0, 0, 0.87); font-weight: 400; font-family: Roboto,sans-serif;">
                    {{ selectedLabel | translate }}
                    <img class="ml-3" src="../../../../assets/icons/arrow-down.svg" alt="" style="display: flex;">
                </p>                
            </button>
            <mat-menu #purpose="matMenu">
                <button mat-menu-item (click)="clearPurpose()">---</button>
                <hr>
                <button mat-menu-item [matMenuTriggerFor]="Rent"  (click)="selectRent()">{{
                    'searchBar.textRent' | translate }}</button>
                <hr>
                <button mat-menu-item [value]="'Buy'" (click)="changeMinPrice('Buy')">{{ 'searchBar.textBuy' | translate
                    }}</button>
            </mat-menu>
            <mat-menu class="pl-1" #Rent="matMenu">
                <hr>
                <button mat-menu-item [value]="'ONE_MONTH'" (click)="rentalTime('ONE_MONTH')">{{'rentOptions.one_month'|
                    translate }}</button>
                <hr>
                <button mat-menu-item [value]="'THREE_MONTHS'"
                    (click)="rentalTime('THREE_MONTHS')">{{'rentOptions.three_months'| translate }}</button>
                <hr>
                <button mat-menu-item [value]="'MORE_THAN_THREE_MONTHS'"
                    (click)="rentalTime('MORE_THAN_THREE_MONTHS')">{{'rentOptions.more_than_three_months'| translate
                    }}</button>
                <hr>
                <button mat-menu-item [value]="'ONE_YEAR'" (click)="rentalTime('ONE_YEAR')">{{'rentOptions.one_year'|
                    translate }}</button>
            </mat-menu>
        </div>
    </div>    
    <div class="flex flex-col margin overflow" >
        <h4 >{{ 'searchBar.labelSearchByLocation' | translate }}</h4>
        <mat-form-field id="matSelectCities" class="matOption" style="max-width: fit-content;">
            <mat-select [(value)]="this.filters.city" (valueChange)="setChangedDefaultValues()"
                placeholder="{{'searchBar.placeHolderSelectLocation' | translate}}">
                <mat-option [value]="">---</mat-option>
                <mat-option *ngFor="let city of cities$ | async" [value]="city.id">{{ city.name }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="flex flex-col margin overflow" >
        <h4 >{{ 'searchBar.labelPropertyType' | translate }}</h4>
        <mat-form-field class="matOption matSelects" style="max-width: fit-content">
            <mat-select [(value)]="this.filters.type" (valueChange)="setChangedDefaultValues()"
                placeholder="{{'searchBar.placeHolderChooseProperty' | translate }}" >
                <mat-option [value]="">---</mat-option>
                <mat-option *ngFor="let propertyType of propertyTypes$ | async" [value]="propertyType.id">{{
                    propertyType.name }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="flex flex-col margin" >
        <h4 class="overflow">{{ 'searchBar.labelPriceRange' | translate }}</h4>
        <mat-form-field class="matOption" style="max-width: fit-content">
            <input readonly #togglePrice [defaultValue]="" matInput [ngModel]="rangeValues" (click)="showRangePrice()"
                placeholder="{{'searchBar.placeHolderChooseRange' | translate}}">
        </mat-form-field>

        <div #showRangeDiv class="rangePriceDiv" [class.showRangeDiv]="showRange">
            <app-ln-price-slider (selectedMinValueEvent)="setMinValue($event)"
                (selectedMaxValueEvent)="setMaxValue($event)" [changedDefaultValues]=changedDefaultValues
                [valuesReceived]="sendPriceValues()" [startThumbValueInput]="minPrice" [startThumbValue]="minPrice"
                [endThumbValue]="maxPrice" [endThumbValueInput]="maxPrice">
            </app-ln-price-slider>
        </div>
    </div>
    <span class="marginDiv">
        <div class=" flex buttonsDiv  mr-3" style="min-width: 3%" *ngIf="changedDefaultValues">
            <div class="clearButton" (click)="clearFilters()">
                <img class="pl-auto pr-auto" src="../../../../assets/icons/clear-filters.svg"
                    style="align-self: center;">
            </div>
            <div>
                <p class="tooltip">{{ 'searchBar.clearFilters' | translate }}</p>
            </div>
        </div>
        <div class="flex buttonsDiv  mr-5" style="min-width: 90px">
            <ln-button [color]="'warn'" [icon]="'search'" [text]="'searchBar.btnSearch' | translate"
                (click)="searchProperties()">
            </ln-button>
        </div>
        <div class="flex buttonsDiv  mr-5 overflow" style="min-width: 150px; align-self: baseline;">
            <ln-button [color]="'accent'" [text]="'searchBar.btnSearchInMap' | translate"
                (clickButton)="goToSearchInMap()">
            </ln-button>
        </div>
    </span>
</div>