<mat-card class="custom-card" *ngIf="post.active">
    <div *ngIf="post && post.pictures && post.pictures.length > 0">
        <img mat-card-image class="img-100 div-img" [ngSrc]="post.pictures[0].path.md" width="100" height="100" priority
             [routerLink]="['/post-details', post.id]">
    </div> 
    <div *ngIf="post && post.pictures && post.pictures.length === 0" >
        <img src="../../../../../assets/images/noImage.jpg" height="9rem" width="100%" priority
             [routerLink]="['/post-details', post.id]">
    </div>
    <mat-card-content>
        <div>
            <img class="like"
                 [src]="!this.isFavorite || !(isLogin | async)
                        ? '../../../../assets/icons/favorite-post.svg'
                        : '../../../../assets/icons/favorite-post-like.svg'"
                 (click)="markAsFavorite(!this.isFavorite, false)"
            >
            <div id="publisherAndPriceRow" class="grid grid-cols-2 mt-4 flex-d">
                <div class="div-price">
                    <span>
                        {{post.property.price | currency: 'USD':'symbol':'1.0'}}
                    </span>
                </div>
                <a [routerLink]="['/post-details', post.id]" id="seeMore" class="underline mr-6">{{ 'card.labelSeeMore' | translate }} </a>
            </div>
            <div class="col-span-2 mt-8">
                <div class="grid-rows-4">
                    <span id="detailsRow1" class="grid grid-cols-2 pb-2">
                        <div>
                            <img class="icons" src="../../../../../assets/icons/hotel.svg">
                            <span class="aligned-with-icon">&nbsp;{{ post.property.numberOfRooms }}
                                {{ post.property.numberOfRooms > 1 ? ('card.labelBedrooms' | translate ) : ('card.labelBedroom' | translate) }}
                            </span>
                        </div>
                         <div>
                            <img class="icons" src="../../../../../assets/icons/ruler-square.svg">
                            <span class="aligned-with-icon">&nbsp;{{ post.property.totalArea }} M</span>
                         </div>
                    </span>
                    <span id="detailsRow2" class="grid grid-cols-2 pb-2">
                        <div>
                            <img class="icons" src="../../../../../assets/icons/car.svg">
                            <span class="aligned-with-icon">&nbsp;{{ post.property.numberOfParkings }} Parking</span>
                        </div>
                        <div>
                             <img class="icons" src="../../../../../assets/icons/shower.svg">
                             <span class="aligned-with-icon">&nbsp;{{ post.property.numberOfBathRooms }}
                                 {{ post.property.numberOfBathRooms > 1 ? ('card.labelBathrooms' | translate ) : ('card.labelBathroom' | translate) }}
                             </span>
                        </div>
                    </span>
                    <div *ngIf="post.tour360" class="mt-2">
                        <a (click)="openIframeDialog(post.tour360.path)" class="underline virtual">
                            {{ 'card.linkDoTour' | translate }}
                        </a>
                    </div>
                    <div *ngIf="post.vr" class="mt-2">
                        <a (click)="openIframeDialog(post.vr.path)" class="underline virtual">
                            {{ 'card.linkVirtualReality' | translate }}
                        </a>
                    </div>
                    <div class="mt-4">
                        <span class="font-size-title">{{ post.property.propertyType?.name | titlecase}}
                            {{ post.property.purpose == 'Buy' ? ('general.labelSale' | translate) : ( 'general.labelRent' | translate) }} </span>
                    </div>
                    <div class="mt-2 place-align">
                        <mat-icon>place</mat-icon>
                        <label class="font-size-city">{{post.property.address.city.name}}</label>
                    </div>
                    <div class="mt-4 mb-4">
                        <a (click)="goToRealState(post.property.publisher.userId)" id="publisherName" class="underline">
                            {{post.property.publisher.name}}
                        </a>
                    </div>
                </div>
            </div>
            <div *ngIf="showDeleteOption">
                <img class="icons mb-0.5" src="../../../../../assets/icons/delete.svg">
                <a class="a-secondary-color ml-2 cursor-pointer" (click)="markAsFavorite(!this.isFavorite, true)">
                    {{ 'card.textDelete' | translate }}
                </a>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="custom-card" *ngIf="!post.active && showDeactivatedPost">
    <img mat-card-image class="img-100 deactivated-card" [ngSrc]="post.pictures[0].path.md" width="100" height="100" priority>
    <mat-card-content class="deactivated-card">
        <div>
            <img class="like"
                 [src]="!this.isFavorite ? '../../../../assets/icons/favorite-post.svg' : '../../../../assets/icons/favorite-post-like.svg'"
            >
            <div class="grid grid-cols-2 mt-4 flex-d">
                <div class="div-price">
                    <span>

                    </span>
                </div>
                <a class="underline mr-6"> {{ 'card.labelSeeMore' | translate }} </a>
            </div>
            <div class="col-span-2 mt-8">
                <div class="grid-rows-4">
                    <span class="grid grid-cols-2 pb-2">
                        <div>
                            <img class="icons" src="../../../../../assets/icons/hotel.svg">
                            <span class="aligned-with-icon">&nbsp;{{ post.property.numberOfRooms }}
                                {{ labelBedroom }}
                            </span>
                        </div>
                         <div>
                            <img class="icons" src="../../../../../assets/icons/ruler-square.svg">
                            <span class="aligned-with-icon">&nbsp;{{ post.property.totalArea }} M</span>
                         </div>
                    </span>
                    <span class="grid grid-cols-2 pb-2">
                        <div>
                            <img class="icons" src="../../../../../assets/icons/car.svg">
                            <span class="aligned-with-icon">&nbsp;{{ post.property.numberOfParkings }} Parking</span>
                        </div>
                        <div>
                             <img class="icons" src="../../../../../assets/icons/shower.svg">
                             <span class="aligned-with-icon">&nbsp;{{ post.property.numberOfBathRooms }}
                                 {{ labelBathroom }}
                             </span>
                        </div>
                    </span>
                    <div *ngIf="post.tour360" class="mt-2">
                        <a class="underline virtual">{{ 'card.linkDoTour' | translate }} </a>
                    </div>
                    <div *ngIf="post.vr" class="mt-2">
                        <a class="underline virtual">{{ 'card.linkVirtualReality' | translate }} </a>
                    </div>
                    <div class="mt-4">
                        <span class="font-size-title">{{ post.property.propertyType?.name | titlecase}}
                            {{ post.property.purpose == 'Buy' ? ('general.labelSale' | translate) : ( 'general.labelRent' | translate) }} </span>
                    </div>
                    <div class="mt-2 place-align">
                        <mat-icon>place</mat-icon>
                        <label class="font-size-city">{{post.property.address.city.name}}</label>
                    </div>
                    <div class="mt-4 mb-4">
                        <a class="underline">
                            {{post.property.publisher.name}}
                        </a>
                    </div>
                </div>
            </div>
            <div *ngIf="showDeleteOption">
                <img class="icons mb-0.5" src="../../../../../assets/icons/delete.svg">
                <a class="a-secondary-color ml-2 cursor-pointer">{{ 'card.textDelete' | translate }}</a>
            </div>
        </div>
    </mat-card-content>
    <div style="z-index: 10; position: relative" class="flex flex-col">
        <div style="height: 140px">
        </div>
        <div class="deactivated-post-div">
            <img class="icons" src="../../../../../assets/icons/warning.svg">
            <p class="titleDeactivated">{{ 'card.textPostDeactivated' | translate }}</p>
            <p>
                {{ 'card.textPostNoAvailable1' | translate }} <br>
                {{ 'card.textPostNoAvailable2' | translate }} <br>
                {{ 'card.textPostNoAvailable3' | translate }}
            </p>
            <ln-button [text]="'card.textDelete' | translate" (click)="markAsFavorite(!this.isFavorite, true)"></ln-button>
        </div>
        <div style="height: 25%">
        </div>
    </div>
</mat-card>
