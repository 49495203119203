import { Component, Input, OnInit } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { FormControl, Validators } from "@angular/forms";
import * as intelTelInput from "intl-tel-input";
import { ContactFormUseCaseService } from "src/app/core/usecase/contact-form-use-case.service";

@Component({
  selector: "ln-form-field-telephone",
  templateUrl: "./ln-form-field-telephone.component.html",
  styleUrls: ["./ln-form-field-telephone.component.css"],
})
export class LnFormFieldTelephoneComponent implements OnInit {
  @Input() control: FormControl = new FormControl<any>({});
  @Input() label = "Teléfono";
  @Input() error = "Campo requerido *";
  @Input() onlyNumbers = "Debe contener un código de área + 8 dígitos";

  telephonePrefix = document.getElementById("#phone");

  constructor(private _contactFormUseCaseService: ContactFormUseCaseService) {}

  ngOnInit() {
    const inputElement = document.getElementById("#phone");
    if (inputElement) {
      const telInput = intelTelInput(inputElement, {
        initialCountry: "GT",
        utilsScript: environment.countryUtilsScript,
        separateDialCode: true,
      });

      this._contactFormUseCaseService.changeTelephonePrefix(
        telInput.getSelectedCountryData().dialCode
      );

      inputElement.addEventListener("countrychange", () => {
        const selectedCountryData = telInput.getSelectedCountryData();
        this._contactFormUseCaseService.changeTelephonePrefix(
          selectedCountryData.dialCode
        );
      });
    }
  }
}
