import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SafeResourceUrl} from "@angular/platform-browser";

import {SanitizerService} from "../../services/sanitizer.service";


@Component({
  selector: 'ln-iframe',
  templateUrl: './ln-iframe.component.html',
  styleUrls: ['./ln-iframe.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LnIframeComponent {

  @Input() url!: string;

  constructor(
      private sanitizerService: SanitizerService
  ) {
  }

  get getUrlSave(): SafeResourceUrl {
    return this.sanitizerService.getSecurityTrustResourceUrl(this.url)
  }

}
