import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input } from '@angular/core';
import {BreakPointService} from "../../../services/break-point.service";

@Component({
  selector: 'ln-social-media',
  templateUrl: './ln-social-media.component.html',
  styleUrls: ['./ln-social-media.component.css']
})
export class LnSocialMediaComponent {

  @Input() tooltipFacebook:string="Compartir en Facebook";  
  @Input() tooltipCopyLink:string="Copiar Link";
  @Input() showWhatsapp = false;
  @Input() iconForCopyLink = "../../../../../assets/icons/link.svg";
  tooltipWhatsapp:string="Compartir en Whatsapp";
  private urlCopy:string;

  constructor(
    private clipboard: Clipboard,
    public breakPointService: BreakPointService)
  {
    this.urlCopy = `${new URL(window.location.href).origin}${window.location.pathname}`
  }

  copyLink() {
    this.clipboard.copy(this.urlCopy);
  }

  sharedInFacebook() {
    const faceBookUrl = `https://www.facebook.com/sharer/sharer.php?u=${this.urlCopy}&amp;src=sdkpreparse`;
    window.open(faceBookUrl, "_blank");
  }

  shareOnWhatsapp() {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${this.urlCopy}`;
    window.open(whatsappUrl, "_blank");
  }
}
