<div class="text-xs">
    <mat-label>{{ 'servicesForm.labelTelephone' | translate }}</mat-label>
    <mat-form-field appearance="outline">
        <span matPrefix>&nbsp;&nbsp;{{ telephonePrefix }}</span>
        <input autocomplete="off" matInput type="text" id="#phone" [formControl]="control">
        <mat-error *ngIf="control.hasError('required') && control.touched" class="form-error-msg">
            {{ 'fieldInput.msgRequiredField' | translate }}
        </mat-error>
        <mat-error *ngIf="control.hasError('pattern') && control.touched" class="form-error-msg">
            {{ 'fieldInput.msgOnlyNumbers' | translate }}
        </mat-error>
    </mat-form-field>
</div>